
import { useState, useEffect, useCallback } from 'react';
import { cockpitUrl } from '../cockpit.js';
import { motion } from 'framer-motion';
import parse from 'html-react-parser';

const PrivacyPolicy = (props) => {

  const [privacyPolicyData, setPrivacyPolicyData] = useState({});

  const getPrivacyPolicyData = useCallback(async () => {
    try {
      // because we're async we have to await. This means it can do its own thing and happen whenever without blocking the main thread
      const apiKey = process.env.REACT_APP_API_KEY;

      await fetch(`${cockpitUrl}/api/singletons/get/privacy_policy?token=${apiKey}`)
        .then(response => response.json())
        .then(response => {
          setPrivacyPolicyData(response);
        });

    }
    catch (error) {
      console.log(error)
    }
  }, []);

  useEffect(() => {
    getPrivacyPolicyData();
  }, [getPrivacyPolicyData]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="section section--privacy-policy">
      <div className="privacy-policy__wrapper">
        {
          privacyPolicyData ?
            privacyPolicyData.privacy_policy ?
              parse(privacyPolicyData.privacy_policy) : ''
            : ''
        }
      </div>
    </motion.div>
  )

}

export default PrivacyPolicy;
const PauseIcon = (props) => (
  <svg
    className="icon icon--pause"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
  >
    <rect
      fill={props.pageForeground ? props.pageForeground : '#000'}
      data-name="Rectangle 32" width="13" height="33" rx="4"
    />
    <rect
      fill={props.pageForeground ? props.pageForeground : '#000'}
      data-name="Rectangle 33" width="13" height="33" rx="4" transform="translate(19)"
    />
  </svg>

)

export default PauseIcon;
const YoutubeVideo = ({ url }) => {

  const extractYoutubeIdFromUrl = (url) => {
    if (url) {
      const queryStringStart = '?v=';
      const indexOfQueryString = url.indexOf(queryStringStart);
      const queryStringOnly = url.substring(indexOfQueryString + queryStringStart.length);
      let id = queryStringOnly;
      if (queryStringOnly.indexOf('&') > -1) {
        id = queryStringOnly.substring(0, queryStringOnly.indexOf('&'));
      }
      return id;
    }
  }

  return(
    <div className="youtube__wrapper">
      <div className="youtube__inner">
        <iframe
          className="iframe youtube-embed"
          title="Youtube Video"
          width="560" height="315"
          src={`https://www.youtube.com/embed/${extractYoutubeIdFromUrl(url)}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
        ></iframe>
      </div>
    </div>
  )
}

export default YoutubeVideo;
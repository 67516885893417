import { LoginLogoutButton } from './LoginLogoutButton.js';

const CourseLoginForm = (props) => {

  const { login_form_text, title } = props;

  return (
    <div className="course__login">
      <div className="course__login__inner">
        <p className="login__form__text">
          {
            login_form_text ?
              login_form_text :
              `Not long now till you will be diving into ${title}. First, make an account!`
          }
        </p>
        <LoginLogoutButton {...props} />
      </div>
    </div>
  )
}

export default CourseLoginForm;
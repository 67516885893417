import { Link } from 'react-router-dom';
import Logo from '../svg/Logo.js';

const Header = (props) => (
  <header
    id="header"
    className={`app__header${props.menuIsOpen === true ? ' menu-is-open' : ''}`}
  >
    <Link to="/" className="header__link">
      <span className="socials__link__text">Home</span>
      <Logo pageBackground={props.pageBackground} pageForeground={props.pageForeground} />
    </Link>
  </header>
);

export default Header;
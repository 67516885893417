import { useState } from 'react';

const MotionButton = (props) => {

  const { pageBackground, pageForeground, motionIsEnabled, toggleMotionIsEnabled } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }

  const handleFocus = () => {
    setIsFocused(true);
  }

  const handleBlur = () => {
    setIsFocused(false);
  }

  return(
    <button
      aria-label={`${motionIsEnabled === true ? 'Reduce' : 'Increase'} motion`}
      onClick={toggleMotionIsEnabled}
      className="button--motion button--nav"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={{
        backgroundColor: isHovered === false && isFocused === false ? pageBackground : pageForeground,
        color: isHovered === false && isFocused === false ? pageForeground : pageBackground,
        borderColor: pageForeground
      }}
    >{motionIsEnabled === true ? 'Reduce' : 'Increase'} motion</button>
  )
}

export default MotionButton;

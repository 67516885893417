import { assetsPath } from '../cockpit.js';
import { WigglyLine, WigglyLineVertical } from '../../svg/WigglyLine.js';
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';

const StoryImage = ({src, alt}) => (
  <div className="story__image">
    <img src={assetsPath + '/' + src} className="story__image__img" alt={alt} />
  </div>
)

const StoryText = (props) => (
  <p className="story__text">
    { props.children }
  </p>
)

const StoryHeading = (props) => (
  <h3 className="story__heading">
    { props.link ?
      <Link to={props.link}>{props.children}</Link>
      :
      props.children
    }
  </h3>
);

const StraightLine = () => (
  <div className="story__connector--straight">

  </div>
);

const parseAndFormatDate = (input) => {

  let parts = input.split('-');

  // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
  let dateObject = new Date(parts[2], parts[1] - 1, parts[0]); // Note: months are 0-based

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const month = months[dateObject.getMonth()];
  const year = dateObject.getFullYear();
  return month + ' ' + year;
}

const StoryItem = (props) => (
  <div className="story__item__wrapper">
    <div className={`story__item story__item--${props.value.shape} story__item--${props.field.name}`}>
      {
        props.value.date && props.value.date !== '' ?
        <p className="story__item__date">
          <span className="story__item__date__text">{parseAndFormatDate(props.value.date)}</span>
        </p> : ''
      }
      <div className="story__item__inner">
        <div className={`story__item__content story__item__content--${props.field.name}`}>
          {props.field.name === 'image' ? <StoryImage src={props.value.image.path} alt={props.value.image.meta ? props.value.image.meta.title : "Timeline image"} /> : ''}
          {props.field.name === 'text' ? <StoryText>{props.value.text}</StoryText> : ''}
          {props.field.name === 'heading' ? <StoryHeading link={props.value.link}>{props.value.text}</StoryHeading> : ''}
        </div>
      </div>
    </div>
    <div className="story__joint">
      {
        props.itemIndex % 2 === 0 ?
          window.USER_IS_TOUCHING === true || window.innerWidth < 500 ?
            <WigglyLineVertical />
            :
            <WigglyLine />
          : <StraightLine />
      }
    </div>
  </div>
);

const OurStory = (props) => {

  const [scrollAmount, setScrollAmount] = useState(0);
  const [storyInitialised, setStoryInitialised] = useState(false);
  const [sectionLength, setSectionLength] = useState(0);
  const [localScrollAmount, setLocalScrollAmount] = useState(0);
  const { scrollDirection } = props;

  useEffect(() => {
    if (storyInitialised === false) {
      setStoryInitialised(true);
      const direction = scrollDirection;
      if (direction === -1) {
        if (direction === -1) {
          const section = document.querySelector('.section__inner');
          const sectionWidth = section.offsetWidth;
          const sectionHeight = section.offsetHeight;
          if (window.innerWidth < 500 || window.USER_IS_TOUCHING) {
            setScrollAmount(sectionHeight - window.innerHeight);
            setLocalScrollAmount(sectionHeight - window.innerHeight);
            section.scrollTop = section.scrollHeight - section.offsetHeight - 1;
          } else {
            setScrollAmount(sectionWidth - window.innerWidth);
            setLocalScrollAmount(sectionWidth - window.innerWidth);
          }
        }
      }
      if (document.querySelector('.section__inner')) {
        if (window.innerWidth < 500) {
          setSectionLength(document.querySelector('.section__inner').offsetHeight);
        } else {
          setSectionLength(document.querySelector('.section__inner').offsetWidth);
        }
      }
    }
  },[storyInitialised, props, setSectionLength, scrollDirection])

  const onMouseWheel = (event) => {
    if (window.USER_IS_TOUCHING !== true) {
      const prevScrollAmount = scrollAmount;
      const newScrollAmount = prevScrollAmount + event.deltaY;

      const section = document.querySelector('.section__inner');

      if (section) {
        if (window.innerWidth < 500) {
          setSectionLength(section.offsetHeight);
        } else {
          setSectionLength(section.offsetWidth);
        }

        if (props.disableScrollRouteChange === false) {
          if (newScrollAmount > 0 && newScrollAmount <= sectionLength) {
            setScrollAmount(newScrollAmount);
          } else if (newScrollAmount < 0) {
            props.history.push('/about');
            props.setScrollRouteChangeTimeout();
          } else if (newScrollAmount > sectionLength) {
            props.history.push('/connect');
          }
        } else {
          if (scrollDirection === -1) {
            setScrollAmount(sectionLength - window.innerWidth)
          }
        }
      }
    }
  }

  const handleScroll = (e) => {
    if (e.target.scrollTop === e.target.scrollHeight - window.innerHeight) {
      if (localScrollAmount > e.target.scrollTop) {
        props.history.push('/connect');
        props.setScrollRouteChangeTimeout();
      }
    }
    if (e.target.scrollTop === 0) {
      if (localScrollAmount < e.target.scrollTop) {
        props.history.push('/about');
      }
    }
    setLocalScrollAmount(e.target.scrollTop);
  }

  const handleSwipedDown = () => {
    //prev
    if (localScrollAmount <= 0) {
      props.history.push('/about');
    }
  }

  const handleSwipedUp = () => {
    //next
    const section = document.querySelector('.section__inner');
    if (localScrollAmount > section.scrollHeight - section.parentNode.offsetHeight - 5) {
      props.history.push('/connect');
    }
  }

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => handleSwipedUp(),
    onSwipedDown: () => handleSwipedDown()
  });

  return (
    <motion.div
      key='ourStory'
      initial={{
        x: !window.USER_IS_TOUCHING && window.innerWidth >= 500 ? scrollDirection === -1 ? -window.innerWidth - sectionLength : window.innerWidth : 0,
        y: window.USER_IS_TOUCHING === true || window.innerWidth < 500 ? scrollDirection === -1 ? -window.innerHeight - sectionLength : window.innerHeight : 0
      }}
      animate={{
        x: 0,
        y: 0
      }}
      exit={{
        x: !window.USER_IS_TOUCHING && window.innerWidth >= 500 ? scrollDirection === -1 ? window.innerWidth * 2 : -window.innerWidth - sectionLength : 0,
        y: window.USER_IS_TOUCHING === true || window.innerWidth < 500 ? scrollDirection === -1 ? window.innerHeight * 2 : -window.innerHeight - sectionLength : 0
      }}
      transition={{
        duration: 0.9,
        ease: 'easeInOut'
      }}
      style={{
        height: window.USER_IS_TOUCHING === true ? window.innerHeight + 'px' : ''
      }}
      className={`section section--our-story scroll--horizontal${props.menuIsOpen === true ? ' section--menu-is-open' : ''}`}
      onWheel={onMouseWheel}
    >
      <div
        className="section__inner section--our-story__inner full-height"
        onScroll={handleScroll}
        style={{
          height: window.USER_IS_TOUCHING === true ? window.innerHeight + 'px' : '',
          overflowY: window.USER_IS_TOUCHING === true ? 'scroll' : 'unset',
          left: window.innerWidth < 500 || window.USER_IS_TOUCHING ? 0 : 0 - scrollAmount + 'px',
          top: window.innerWidth < 500 || window.USER_IS_TOUCHING ? 0 - scrollAmount + 'px' : 0
        }}
        {...swipeHandlers}
      >
        <div className="story__heading--main__wrapper float">
          <h2 className="our-story__heading--main font--heading--large">Our Story</h2>
        </div>
        {
          props.storyData ?
          props.storyData.map(
            (item, i) => (
                <StoryItem
                  {...item}
                  key={i}
                  itemIndex={i}
                  storyLength={props.storyData.length}
                />
              )
            )
          :
          ''
        }
        <div className="our-story__scroll-on-text__wrapper">
          <p className="scroll-on-text float">
            <Link to="/connect">
              Scroll on to be a part of the next chapter
            </Link>
          </p>
        </div>
      </div>
    </motion.div>
  )

}

export default OurStory;
import { useState } from 'react';
import { Link } from 'react-router-dom';

const menuItems = [
  {
    text: 'About',
    href: '/about'
  },
  {
    text: 'Our Story',
    href: '/our-story'
  },
  {
    text: 'Connect',
    href: '/connect'
  },
  {
    text: 'Learn',
    href: '/learn'
  },
  {
    text: 'Contact',
    href: '/contact'
  }
]

const MenuItem = (props) => {

  const { pageBackground, pageForeground, route, href } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }

  const handleFocus = () => {
    setIsFocused(true);
  }

  const handleBlur = () => {
    setIsFocused(false);
  }

  return (
    <Link
      to={`${href}`}
      className={`link--menu${route === href ? ' active' : ''}`}
      style={{
        backgroundColor: route === href || isFocused === true || isHovered === true ? pageForeground : pageBackground,
        color: route === href || isFocused === true || isHovered === true ? pageBackground : pageForeground,
        borderColor: pageForeground
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      { props.text }
    </Link>
  )
};

const NavLinks = (props) => (
  <nav id="nav" className={`${props.menuIsOpen === true ? 'nav--transparent' : 'nav--opaque'}`}>
    {
      menuItems.map(
        (menuItem, i) => <MenuItem {...menuItem} pageBackground={props.pageBackground} pageForeground={props.pageForeground} key={i} route={props.pathName} />
      )
    }
  </nav>
)

export { NavLinks, MenuItem };
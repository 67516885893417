import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from 'react';
import { cockpitUrl } from './cockpit.js';

const createNewUser = (user) => {
  const apiKey = process.env.REACT_APP_API_KEY;

  fetch(`${cockpitUrl}/api/collections/save/users?token=${apiKey}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      data: {
        user_id: user.sub,
        name: user.name,
        email: user.email,
        courses: []
      }
    })
  })
  .then(res => res.json())
  .catch(error => console.log('no dice'));
}

const fetchUsersFromCockpit = (user) => {
  const apiKey = process.env.REACT_APP_API_KEY;

  fetch(`${cockpitUrl}/api/collections/get/users?token=${apiKey}`)
  .then(response => response.json())
  .then(response => {

    const auth0UserId = user.sub;

    const { entries } = response;
    let cockpitUser;

    if (entries) {
      for (let entry of entries) {
        if (entry.user_id === auth0UserId) {
          cockpitUser = entry;
        }
      }
    }

    if (!cockpitUser) {
      createNewUser(user);
      console.log('new user');
    } else {
      console.log('existing user');
    }
  })
  .catch(error => console.log(error));
}

const LoginButton = (props) => {

  const { loginWithRedirect } = useAuth0();
  const { pageBackground, pageForeground } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }

  const handleFocus = () => {
    setIsFocused(true);
  }

  const handleBlur = () => {
    setIsFocused(false);
  }

  return (
    <button
      aria-label="Login button"
      onClick={() => loginWithRedirect()}
      className="button--login-logout button--login button--nav"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={{
        backgroundColor: isHovered === false && isFocused === false ? pageBackground : pageForeground,
        color: isHovered === false && isFocused === false ? pageForeground : pageBackground,
        borderColor: pageForeground
      }}
    >
      Log In / Sign Up
    </button>
  );
};

const LogoutButton = (props) => {

  const { logout } = useAuth0();
  const { pageBackground, pageForeground } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleMouseEnter = (e) => {
    setIsHovered(true);
  }

  const handleMouseLeave = (e) => {
    setIsHovered(false);
  }

  const handleFocus = () => {
    setIsFocused(true);
  }

  const handleBlur = () => {
    setIsFocused(false);
  }

  return (
    <button
      aria-label="logout button"
      className="button--login-logout button--logout button--nav"
      onClick={() => logout({ returnTo: window.location })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={{
        backgroundColor: isHovered === false && isFocused === false ? pageBackground : pageForeground,
        color: isHovered === false && isFocused === false ? pageForeground : pageBackground,
        borderColor: pageForeground
      }}
    >
      Log Out
    </button>
  );
};

const LoginLogoutButton = (props) => {
  const { isAuthenticated, isLoading, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      fetchUsersFromCockpit(user);
    }
  }, [isAuthenticated, isLoading, user]);

  return (
    !isLoading && (
      <div className="button--login-logout__wrapper">
        {
          isAuthenticated && (
            <LogoutButton {...props} />
          )
        }
        {
          !isAuthenticated && (
            <LoginButton {...props} />
          )
        }
      </div>
    )
  )
}

export { LoginButton, LogoutButton, LoginLogoutButton };
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { assetsPath } from '../cockpit.js';
import { motion } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';

const LearnThumbnail = (props) => {
  return (
    <div className="learn__column__thumbnail__wrapper">
      <div className="learn__column__thumbnail float">
        <img
          className="learn__column__thumbnail__img"
          src={`${assetsPath}/${props.value.image.path}`}
          alt={props.value.image.meta ? props.value.image.meta.title : `Thumbnail image for ${props.value.title} page link`}
        />
      </div>
    </div>
  )
}

const LearnColumn = (props) => (
  <div
    className="learn__column"
  >
    {
      props.value ?
        <div className="learn__column__inner">
          <LearnThumbnail {...props} />
          <div className="learn__column__info">
            <h2 className="learn__column__info__title">
              {
                props.value.live === true ?
                  <Link
                    to={props.value.path}
                      className="learn__column__info__title__link"
                    >{props.value.title}
                  </Link>
                  :
                  <span className="learn__column__info__title--no-link">{props.value.title}<br></br>coming soon!</span>
              }
            </h2>
          <div className="learn__column__info__text">
            {props.value.info}
          </div>
        </div>
      </div>
      :
      ''
    }
  </div>
)

const Learn = (props) => {

  const [scrollAmount, setScrollAmount] = useState(0);
  const [learnInitialised, setLearnInitialised] = useState(false);
  const [sectionLength, setSectionLength] = useState(0);
  const { scrollDirection } = props;
  const [localScrollAmount, setLocalScrollAmount] = useState(0)

  useEffect(() => {
    if (learnInitialised === false) {
      setLearnInitialised(true);
      const direction = props.scrollDirection;
      if (direction === -1) {
        if (direction === -1) {
          const section = document.querySelector('.section__inner');
          if (section) {
            const sectionHeight = section.offsetHeight;
            setScrollAmount(sectionHeight - window.innerHeight);
            setLocalScrollAmount(sectionHeight - window.innerHeight);
            section.scrollTop = section.scrollHeight - section.offsetHeight - 1;
          }
        }
      }
    }
    if (document.querySelector('.section__inner')) {
      setSectionLength(document.querySelector('.section__inner').offsetHeight);
    }
  }, [learnInitialised, props.scrollDirection, setSectionLength]);

  const onMouseWheel = (event) => {
    if (window.USER_IS_TOUCHING !== true) {
      const prevScrollAmount = scrollAmount;
      const newScrollAmount = prevScrollAmount + event.deltaY;

      const section = document.querySelector('.section__inner');

      if (section) {
        setSectionLength(section.offsetHeight);

        if (props.disableScrollRouteChange === false) {
          if (newScrollAmount > 0 && newScrollAmount <= sectionLength) {
            setScrollAmount(newScrollAmount);
          } else if (newScrollAmount < 0) {
            props.history.push('/connect');
            props.setScrollRouteChangeTimeout();
          } else {
            props.history.push('/contact');
          }
        } else {
          if (props.scrollDirection === -1) {
            setScrollAmount(sectionLength);
          }
        }
      }
    }
  }

  const handleScroll = (e) => {
    if (e.target.scrollTop === e.target.scrollHeight - window.innerHeight) {
      if (localScrollAmount < e.target.scrollTop) {
        props.history.push('/contact');
      }
    }
    if (e.target.scrollTop === 0) {
      if (localScrollAmount > e.target.scrollTop) {
        props.history.push('/connect');
        props.setScrollRouteChangeTimeout();
      }
    }
    setLocalScrollAmount(e.target.scrollTop);
  }

  const handleSwipedDown = () => {
    //prev
    if (localScrollAmount <= 0) {
      props.history.push('/connect');
    }
  }

  const handleSwipedUp = () => {
    //next
    const section = document.querySelector('.section__inner');
    if (localScrollAmount > section.scrollHeight - section.parentNode.offsetHeight - 5) {
      props.history.push('/contact');
    }
  }

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => handleSwipedUp(),
    onSwipedDown: () => handleSwipedDown()
  });

  return (
    <motion.div
      key='learn'
      initial={{ y: scrollDirection !== -1 ? window.innerHeight : -window.innerHeight - sectionLength }}
      animate={{ y: 0 }}
      exit={{ y: scrollDirection === -1 ? window.innerHeight : -window.innerHeight - sectionLength }}
      transition={{
        duration: 0.9,
        ease: 'easeInOut'
      }}
      style={{
        height: window.USER_IS_TOUCHING === true ? window.innerHeight + 'px' : ''
      }}
      className={`section section--learn${props.menuIsOpen === true ? ' section--menu-is-open' : '' }`}
      onWheel={onMouseWheel}
    >
      <div
        className="section__inner"
        onScroll={handleScroll}
        {...swipeHandlers}
        style={{
          top: window.USER_IS_TOUCHING === true ? 0 : 0 - scrollAmount + 'px',
          height: window.USER_IS_TOUCHING === true ? window.innerHeight + 'px' : '',
          overflowY: window.USER_IS_TOUCHING === true ? 'scroll' : 'unset'
        }}
      >
      <h2 className="education-hub__title float--2">Education Hub</h2>
        <div className="learn__row">
          {
            props.learnData ?
              props.learnData.learn ?
                props.learnData.learn.map(
                  (item, i) => <LearnColumn key={i} {...item} />
                )
              :
              ''
            :
            ''
          }
        </div>
      </div>
        <p className="scroll-on-text float">
          <Link to="/contact">
            Scroll on to get in touch
          </Link>
        </p>
    </motion.div>
  )
}

export default Learn;
import { cockpitUrl } from '../cockpit.js';
import { useState, useEffect, useCallback } from "react";
import YoutubeVideo from '../YoutubeVideo.js';
import { motion } from 'framer-motion';

const YouTube = (props) => {

  const [youtubeData, setYoutubeData] = useState({});

  const getYouTubeData = useCallback(async () => {
    try {
      // because we're async we have to await. This means it can do its own thing and happen whenever without blocking the main thread
      const apiKey = process.env.REACT_APP_API_KEY;

      await fetch(`${cockpitUrl}/api/singletons/get/youtube?token=${apiKey}`)
        .then(response => response.json())
        .then(response => {
          setYoutubeData(response.youtube);
        });

    }
    catch (error) {
      console.log(error)
    }
  }, []);

  useEffect(() => {
    getYouTubeData();
  }, [getYouTubeData]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="section section--youtube">
      <h2 className="youtube__title">Youtube</h2>
        {
          youtubeData ?
            youtubeData[0] ?
              youtubeData.map(
                (item, i) => (
                  <YoutubeVideo url={item.value} key={i} />
                )
              )
            :
            ''
          :
          ''
        }
    </motion.div>
  )

}

export default YouTube;
import { useState, useEffect, useCallback } from "react";
import { assetsPath, cockpitUrl } from '../cockpit.js';
import { useAuth0 } from '@auth0/auth0-react';
import { VideoPlayer } from '../VideoPlayer.js';
import CourseFinalPage from '../CourseFinalPage.js';
import Payment from '../Payment.js';
import Wiggly from '../../svg/Wiggly.js';
import CourseLoginForm from "../CourseLoginForm.js";
import parse from 'html-react-parser';
const apiKey = process.env.REACT_APP_API_KEY;

const CourseVideoPlayerPage = (props) => {

  const { chapters, activeChapter, pageBackground, chaptersAreShownOnVideoPlayer, showControlsOnVideoPlayer, handleChangeActiveChapter, uiIsHidden, showQuestion, questionIsActive, courseData, handleActivateFinalPage } = props;

  return (
    <div className="course__video-player-page__wrapper full-height">
      <VideoPlayer
        pageBackground={pageBackground}
        chapters={chapters}
        chaptersAreShownOnVideoPlayer={chaptersAreShownOnVideoPlayer}
        showControlsOnVideoPlayer={showControlsOnVideoPlayer}
        handleChangeActiveChapter={handleChangeActiveChapter}
        activeChapter={activeChapter}
        uiIsHidden={uiIsHidden}
        showQuestion={showQuestion}
        questionIsActive={questionIsActive}
        courseData={courseData}
        handleActivateFinalPage={handleActivateFinalPage}
        getUserDataForThisCourse={props.getUserDataForThisCourse}
        currentUserCourseData={props.currentUserCourseData}
      />
    </div>
  )
}

const CourseGuestLecturerThumbnail = (props) => {

  const { value } = props;
  const { name, image, bio } = value;

  return (
    <li className="course__homepage__guest-lecturer float">
      <div className="course__homepage__guest-lecturer__inner">
        <div className="course__homepage__guest-lecturer__img__wrapper">
          {
            image &&
            <img className="course__homepage__guest-lecturer__img" src={assetsPath + '/' + image.path} alt={image.title ? image.title : `Image of ${name}`} />
          }
        </div>
        <div className="course__homepage__guest-lecturer__bio__wrapper">
          {
            bio &&
            <div className="course__homepage__guest-lecturer__bio">
              <h3 className="course__homepage__guest-lecturer__name">{name}</h3>
              {parse(bio)}
            </div>
          }
        </div>
      </div>
    </li>
  )
}

const CourseChapterThumbnail = (props) => {

  const handleClickThroughToChapter = (number) => {
    props.handleChangeActiveChapter(number);
    props.showVideos();
  }

  return (
    <li className={`course__homepage__chapter__thumbnail float--2`}>
      <div className="course__homepage__chapter__thumbnail__inner">
        <div className="course__homepage__chapter__thumbnail__number__wrapper">
          <button
            className="course__homepage__chapter__thumbnail__number__outer"
            onClick={() => {
              handleClickThroughToChapter(props.index + 1);
            }}
          >
            <h3 className="course__homepage__chapter__thumbnail__number">
              {props.index + 1}
            </h3>
          </button>
        </div>
        <div className="course__homepage__chapter__thumbnail__text__wrapper">
          <div className="course__homepage__chapter__thumbnail__title__wrapper">
            <h4 className="course__homepage__chapter__thumbnail__title">
              {props.value.title}
            </h4>
          </div>
          <div className="course__homepage__chapter__thumbnail__text">
            {props.value.info}
          </div>
        </div>
      </div>
    </li>
  )
}

const CourseHomepageIntro = (props) => {

  const { courseTitle, handleCourseBeginClick, courseIntro, courseResourcesLink, userHasPaid, price, pay_what_you_want } = props;

  return (
    <div className="course__homepage__intro__wrapper">
      <h2 className="course__homepage__intro__title">{courseTitle}</h2>
      <div className="course__homepage__intro__row">
        <div className="course__homepage__intro__content">
          {
            courseIntro ?
              parse(courseIntro) : ''
          }
          {
            courseResourcesLink ?
              <a
                className="course__button--download-resources button--nav"
                href={courseResourcesLink}
                download={true}
                target="_blank"
                rel="noreferrer"
              >Download course resources</a> : ''
          }
        </div>
        <div className="course__homepage__intro__start-button__wrapper">
          <button
            className="course__homepage__intro__start-button"
            onClick={handleCourseBeginClick}
          >
            <Wiggly />
            <span className="course__homepage__intro__start-button__text">
              {
                userHasPaid === true ?
                  `Start`
                  :
                  pay_what_you_want !== true ?
                    price ?
                      `Access for £${price}` : ''
                    :
                    `Pay what you want!`
              }
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

const CourseHomepage = (props) => {

  const { userHasPaid, title, handleCourseBeginClick, course_intro, course_resources_url, courseData } = props;

  return (
    <div className="course__homepage__wrapper full-height">
      {
        courseData._id &&
        <CourseHomepageIntro
          courseTitle={title}
          handleCourseBeginClick={handleCourseBeginClick}
          courseIntro={course_intro}
          courseResourcesLink={userHasPaid === true ? course_resources_url : null}
          userHasPaid={userHasPaid}
          {...courseData}
        />
      }
      <div className="course__homepage__inner">
      {
        props.guest_lecturers &&
          props.guest_lecturers[0] &&
          <div className="course__guest-lecturers__wrapper course__homepage__inner__column">
            <h3 className="course__homepage__inner__column__title float--3">Guest Lecturers</h3>
            <ul className="course__guest-lecturers__list">
            {
              props.guest_lecturers.map(
                (guest, i) => (
                  <CourseGuestLecturerThumbnail {...guest} key={i} />
                )
              )
            }
            </ul>
          </div>
      }
      {
        props.chapters &&
        <div className="course__chapters__wrapper course__homepage__inner__column">
          <h3 className="course__homepage__inner__column__title float--3">Chapters</h3>
          <ul className="course__chapters__list">
            {
              props.chapters.map(
                (chapter, i) => (
                  <CourseChapterThumbnail {...chapter} key={i} index={i} handleChangeActiveChapter={props.handleChangeActiveChapter} showVideos={props.showVideos} />
                )
              )
            }
            </ul>
          </div>
      }
      </div>
    </div>
  )
}

const CourseContent = (props) => {

  const { onPayHandler, isAuthenticated, userHasPaid, courseData, pageBackground, pageForeground,
    courseHomepageIsActive, finalPageIsActive, setCourseHomepageIsActive, setFinalPageIsActive } = props;
  const { price, title, synopsis, _id, discount_code, discounted_price, login_form_text } = courseData;

  return (
    <div className="course__content__wrapper">
      {
        finalPageIsActive === true ?
          <CourseFinalPage {...props} />
        :
        courseHomepageIsActive === true ?
          <CourseHomepage {...props} isAuthenticated={isAuthenticated} userHasPaid={userHasPaid} />
          :
          isAuthenticated && userHasPaid === true &&
            <CourseVideoPlayerPage {...props} />
      }
      {
        courseHomepageIsActive !== true && userHasPaid === false ?
          isAuthenticated ?
            <Payment
              coursePrice={price}
              courseTitle={title}
              courseSynopsis={synopsis}
              setCourseHomepageIsActive={setCourseHomepageIsActive}
              setFinalPageIsActive={setFinalPageIsActive}
              courseId={_id}
              discountCode={discount_code}
              priceAfterDiscountCode={discounted_price}
              updatePaymentStatusToPaid={onPayHandler}
              payWhatYouWant={courseData.pay_what_you_want}
            />
            :
            <div className="course__wrapper">
              <CourseLoginForm loginFormText={login_form_text} title={title} pageBackground={pageBackground} pageForeground={pageForeground} />
            </div>
        : ''
      }
    </div>
  )
}

const CourseNav = (props) => {

  const { userHasCompletedCourse, finalPageIsActive, courseHomepageIsActive, uiIsHidden } = props;

  return (
    <div className={`course__nav__wrapper${ uiIsHidden === true ? ' hidden' : ''}`}>
      {
        courseHomepageIsActive === false && finalPageIsActive === false ?
          props.chaptersAreShownOnVideoPlayer === true ?
            <button
              className="course__nav"
              onClick={props.showControlsOnVideoPlayer}
            >Controls</button>
              :
            <button
              className="course__nav"
              onClick={props.showChaptersOnVideoPlayer}
            >Chapters</button> : ''
      }
      {
        courseHomepageIsActive === false ?
          <button
            className="course__nav"
            onClick={props.showHomepage}
          >Course Home</button> : ''
      }
      {
        userHasCompletedCourse && finalPageIsActive === false ?
          <button
            className="course__nav"
            onClick={props.showFinalPage}
          >answers</button> : ''
      }
    </div>
  )
}

const Course = (props) => {
  const [courseData, setCourseData] = useState({});
  const [currentUserCourseData, setCurrentUserCourseData] = useState({});
  const { isAuthenticated, user } = useAuth0();


  const [courseIsInitialised, setCourseIsInitialised] = useState(false);
  const [userHasCompletedCourse, setUserHasCompletedCourse] = useState(false);
  const [currentCockpitUser, setCurrentCockpitUser] = useState({});
  const [finalPageIsActive, setFinalPageIsActive] = useState(false);
  const [courseHomepageIsActive, setCourseHomepageIsActive] = useState(true);
  const [activeChapter, setActiveChapter] = useState(1);
  const [chaptersAreShownOnVideoPlayer, setChaptersAreShownOnVideoPlayer] = useState(false);
  const [userHasPaid, setUserHasPaid] = useState(false);
  const [paymentConfirmationPopupIsActive, setPaymentConfirmationPopupIsActive] = useState(false);
  const [questionIsActive, setQuestionIsActive] = useState(false);

  const { uiIsHidden, showUI } = props;

  const getUserDataForThisCourse = useCallback(async (courseId) => {
    fetch(`${cockpitUrl}/api/collections/get/users?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        if (response.entries) {
          if (response.entries[0]) {
            if (user) {
              for (let userEntry of response.entries) {
                if (userEntry.user_id === user.sub) {
                  for (let courseEntry of userEntry.courses) {
                    if (courseEntry.value.course_id === courseId) {
                      setCurrentUserCourseData(courseEntry.value);
                      setCurrentCockpitUser(userEntry);
                      if (courseEntry.value.complete) {
                        setUserHasCompletedCourse(true);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      })
  },[user])

  const getCourseData = useCallback(async () => {
    try {
      // because we're async we have to await. This means it can do its own thing and happen whenever without blocking the main thread

      const courseResponse = await fetch(`${cockpitUrl}/api/collections/get/courses?token=${apiKey}`);
      const courseResponseData = await courseResponse.json();

      if (courseResponseData) {
        const { entries } = courseResponseData;
        if (entries) {
          if (entries[0]) {
            for (let entry of entries) {
              if (props.pathName.indexOf(entry._id) > -1) {
                setCourseData(entry);
                getUserDataForThisCourse(entry._id);
              }
            }
          }
        }
      }
    }
    catch (error) {
      console.log(error);
    }
  }, [props.pathName, getUserDataForThisCourse]);

  const handleCourseBeginClick = () => {
    setCourseHomepageIsActive(false);
    handleChangeActiveChapter(1);
  }

  const handleActivateFinalPage = () => {
    setFinalPageIsActive(true);
    setCourseHomepageIsActive(false);
    setActiveChapter(1);
    setQuestionIsActive(false);
  }

  const saveUserEntryToUpdateCompletionStatus = (cockpitUser) => {
    fetch(`${cockpitUrl}/api/collections/save/users?token=${apiKey}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: cockpitUser
      })
    })
    .then(res=>res.json())
    .then(entry => {
    });
  }

  const fetchLatestVersionOfUserDataToUpdateCompletionStatus = () => {
    fetch(`${cockpitUrl}/api/collections/get/users?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        if (response.entries) {
          if (response.entries[0]) {
            for (let userEntry of response.entries) {
              if (userEntry.user_id === user.sub) {
                for (let courseEntry of userEntry.courses) {
                  if (courseEntry.value.course_id === courseData._id) {
                    if (courseEntry.value.complete === false) {
                      courseEntry.value.complete = true;
                      saveUserEntryToUpdateCompletionStatus(userEntry);
                    }
                  }
                }
              }
            }
          }
        }
      })
  }

  const updateUserToCompleted = () => {
    fetchLatestVersionOfUserDataToUpdateCompletionStatus(user);
    setCourseHomepageIsActive(false);
    setQuestionIsActive(false);
    setFinalPageIsActive(true);
  }

  const handleChangeActiveChapter = (number) => {
    setActiveChapter(number);
    setCourseHomepageIsActive(false);
    setFinalPageIsActive(false);
    setQuestionIsActive(false);
  }

  const showQuestion = (chapterNumber) => {

    let isThereAQuestion = false;

    if (courseData.chapters) {
      if (courseData.chapters[chapterNumber - 1].value.questions) {
        if (courseData.chapters[chapterNumber - 1].value.questions.length > 0) {
          setQuestionIsActive(true);
          isThereAQuestion = true;
        }
      }
      if (isThereAQuestion === false) {
        setQuestionIsActive(false);
        //if there's no question at the end of the chapter and it's the final chapter, update the user's status to completed
        if (chapterNumber === courseData.chapters.length) {
          updateUserToCompleted();
        } else {
          handleChangeActiveChapter(chapterNumber + 1);
        }
      }
    }
  }

  const showHomepage = () => {
    setCourseHomepageIsActive(true);
    setFinalPageIsActive(false);
    setQuestionIsActive(false);
  }

  const showFinalPage = () => {
    setCourseHomepageIsActive(false);
    setFinalPageIsActive(true);
    setQuestionIsActive(false);
  }

  const showVideos = () => {
    setCourseHomepageIsActive(false);
  }

  const detectIfUserHasPaid = useCallback(() => {

    if (user && isAuthenticated) {
      const apiKey = process.env.REACT_APP_API_KEY;

      fetch(`${cockpitUrl}/api/collections/get/users?token=${apiKey}`)
        .then(response => response.json())
        .then(response => {

          const auth0UserId = user.sub;
          const { entries } = response;
          let cockpitUser;

          if (entries) {
            for (let entry of entries) {
              if (entry.user_id === auth0UserId) {
                cockpitUser = entry;
              }
            }
          }

          if (cockpitUser) {
            if (cockpitUser.courses) {
              if (cockpitUser.courses[0]) {
                for (let userCourse of cockpitUser.courses) {
                  if (userCourse.value) {
                    if (userCourse.value.course_id === courseData._id) {
                      setUserHasPaid(true);
                    }
                  }
                }
              }
            }
          }
        })
        .catch(error => console.log(error));
    }
  }, [user, isAuthenticated, courseData, setUserHasPaid])

  const onPayHandler = () => {
    setUserHasPaid(true);
    setPaymentConfirmationPopupIsActive(true);
  }

  useEffect(() => {
    if (courseIsInitialised !== true) {
      setCourseIsInitialised(true)
      getCourseData();
    }
    if (!currentUserCourseData.course_id) {
      if (courseData._id) {
        getUserDataForThisCourse(courseData._id);
      }
    }
    if (userHasPaid === false) {
      detectIfUserHasPaid();
    }
  }, [courseData, courseIsInitialised, getCourseData, detectIfUserHasPaid, userHasPaid, isAuthenticated, user, getUserDataForThisCourse, currentUserCourseData.course_id]);

  const showControlsOnVideoPlayer = () => {
    setChaptersAreShownOnVideoPlayer(false);
  }

  const showChaptersOnVideoPlayer = () => {
    setChaptersAreShownOnVideoPlayer(true);
  }

  return (
    <section
      className={`section section--course${props.menuIsOpen === true ? ' section--menu-is-open' : ''}`}
      onMouseMove={showUI}
      onClick={showUI}
    >
      {
        !useAuth0().isLoading && (
          <div className="course__outer">
            <div className="course__wrapper">
              {
                paymentConfirmationPopupIsActive === false ?
                  <CourseContent
                    {...courseData}
                    {...props}
                    currentCockpitUser={currentCockpitUser}
                    courseHomepageIsActive={courseHomepageIsActive}
                    finalPageIsActive={finalPageIsActive}
                    setCourseHomepageIsActive={setCourseHomepageIsActive}
                    setFinalPageIsActive={setFinalPageIsActive}
                    activeChapter={activeChapter}
                    handleCourseBeginClick={handleCourseBeginClick}
                    chaptersAreShownOnVideoPlayer={chaptersAreShownOnVideoPlayer}
                    showControlsOnVideoPlayer={showControlsOnVideoPlayer}
                    handleChangeActiveChapter={handleChangeActiveChapter}
                    showVideos={showVideos}
                    uiIsHidden={uiIsHidden}
                    showQuestion={showQuestion}
                    questionIsActive={questionIsActive}
                    courseData={courseData}
                    handleActivateFinalPage={handleActivateFinalPage}
                    getUserDataForThisCourse={getUserDataForThisCourse}
                    currentUserCourseData={currentUserCourseData}
                    onPayHandler={onPayHandler}
                    paymentConfirmationPopupIsActive={paymentConfirmationPopupIsActive}
                    userHasPaid={userHasPaid}
                    isAuthenticated={isAuthenticated}
                  />
                  :
                  <div className="course__payment-confirmation">
                    <p className="course__payment-confirmation__text">
                      Thank you for your payment. Welcome to {courseData.title}!
                    </p>
                    <button
                      aria-label="continue to course"
                      className="course__payment-confirmation__button button--nav"
                      onClick={() => {
                        setUserHasPaid(true);
                        setPaymentConfirmationPopupIsActive(false);
                      }}
                    >Continue to course</button>
                  </div>
              }
            </div>
            {/* {
              !isAuthenticated && (
                <div className="course__wrapper">
                  <CourseLoginForm loginFormText={courseData.login_form_text} title={courseData.title} pageBackground={props.pageBackground} pageForeground={props.pageForeground} />
                </div>
              )
            } */}
            <CourseNav
              pageBackground={props.pageBackground}
              chaptersAreShownOnVideoPlayer={chaptersAreShownOnVideoPlayer}
              showControlsOnVideoPlayer={showControlsOnVideoPlayer}
              showChaptersOnVideoPlayer={showChaptersOnVideoPlayer}
              showHomepage={showHomepage}
              showFinalPage={showFinalPage}
              handleActivateFinalPage={handleActivateFinalPage}
              userHasCompletedCourse={userHasCompletedCourse}
              courseHomepageIsActive={courseHomepageIsActive}
              finalPageIsActive={finalPageIsActive}
              uiIsHidden={uiIsHidden}
            />
          </div>
        )
      }
    </section>
  )

}

export default Course;
const VolumeIcon = (props) => (
  <svg
    className="icon icon--volume"
    xmlns="http://www.w3.org/2000/svg"
    width="42.5"
    height="40"
    viewBox="0 0 42.5 40">
    <g id="Group_36" data-name="Group 36" transform="translate(-1093.5 -1782.5)">
      <g id="Group_35" data-name="Group 35" transform="translate(-16)">
        <g id="Ellipse_8" data-name="Ellipse 8" transform="translate(1134 1796)" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="2">
          <circle cx="7" cy="7" r="7" stroke="none"/>
          <circle cx="7" cy="7" r="6" fill="none"/>
        </g>
        <circle id="Ellipse_10" data-name="Ellipse 10" cx="2" cy="2" r="2" transform="translate(1139 1801)"/>
        <g id="Ellipse_11" data-name="Ellipse 11" transform="translate(1130 1792)" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="2">
          <circle cx="11" cy="11" r="11" stroke="none"/>
          <circle cx="11" cy="11" r="10" fill="none"/>
        </g>
        <rect
        className="svg__polygon" data-name="Rectangle 6" width="11" height="22" transform="translate(1130 1792)" fill="#ef9d16"/>
      </g>
      <rect
        className="svg__polygon" data-name="Rectangle 5" width="20" height="19" rx="4" transform="translate(1096 1793)"/>
      <path
        className="svg__polygon" data-name="Polygon 2" d="M16.707,4.775a4,4,0,0,1,6.586,0L35.675,22.729A4,4,0,0,1,32.382,29H7.618a4,4,0,0,1-3.293-6.271Z" transform="translate(1093.5 1822.5) rotate(-90)"/>
    </g>
  </svg>

)

export default VolumeIcon;
import { useState, useEffect } from "react";
import { assetsPath } from '../cockpit.js';
import Spiky from '../../svg/Spiky.js';
import Wiggly from '../../svg/Wiggly.js';
import { motion } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import { Link } from 'react-router-dom';

const AboutImage = ({src, alt}) => (
  <div className="about__image"
    style={{
      backgroundImage: `url(${assetsPath  + '/' + src})`
    }}
  >
    <img className="about__image__img--hidden" alt={alt} src={assetsPath  + '/' + src} />
  </div>
)

const AboutText = (props) => (
  <p className="about__text">
    { props.children }
  </p>
)

const AboutHeading = (props) => (
  <p className="about__heading">
    { props.children }
  </p>
)

const AboutItem = (props) => {

  const { index, scrollAmount } = props;
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const wrapper = document.querySelector(`#aboutItem${index}`);
    if (wrapper) {
      if (scrollAmount >= wrapper.offsetTop - window.innerHeight * 0.9) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }
  }, [scrollAmount, index]);

  return (
    <div
      className={`about__item about__item--${props.value.shape}${ isActive === true ? ' active' : '' }${ props.field.name === 'image' ? ' about__item--image' : '' }`}
      id={`aboutItem${index}`}
    >
      <div className="about__item__outer">
        <div className="about__item__inner">
          {props.value.shape === 'spiky' ? <Spiky fillColor={props.pageBackground} /> : ''}
          {props.value.shape === 'wiggly' ? <Wiggly fillColor={props.pageBackground} /> : ''}
          <div className={`about__item__content about__item__content--${props.field.name}`}>
            {props.field.name === 'image' ? <AboutImage src={props.value.image.path} alt={props.value.image.meta ? props.value.image.meta.title : "About page image"} /> : ''}
            {props.field.name === 'text' ? <AboutText>{props.value.text}</AboutText> : ''}
            {props.field.name === 'heading' ? <AboutHeading>{props.value.text}</AboutHeading> : ''}
          </div>
        </div>
        {
          props.field.name === 'image' && props.value.badge && props.value.badge !== '' ?
            <div style={{ backgroundColor: props.pageBackground }} className="about__item--badge">
              <span className="about__item--badge__text">{props.value.badge}</span>
            </div> : ''
        }
      </div>
    </div>
  )
};

const About = (props) => {

  const { scrollDirection } = props;
  const [scrollAmount, setScrollAmount] = useState(0);
  const [aboutInitialised, setAboutInitialised] = useState(false);
  const [sectionLength, setSectionLength] = useState(0);
  const [localScrollAmount, setLocalScrollAmount] = useState(0);

  useEffect(() => {
    if (aboutInitialised === false) {
      setAboutInitialised(true);
      const direction = props.scrollDirection;
      if (direction === -1) {
        const section = document.querySelector('.section__inner');
        if (section) {
          const sectionHeight = section.offsetHeight;
          setScrollAmount(sectionHeight - window.innerHeight + 120);
          setLocalScrollAmount(sectionHeight - window.innerHeight);
          section.scrollTop = section.scrollHeight - section.offsetHeight - 1;
        }
      }
    }
    if (document.querySelector('.section__inner')) {
      setSectionLength(document.querySelector('.section__inner').offsetHeight);
    }
  }, [aboutInitialised, props.scrollDirection, setSectionLength]);

  const onMouseWheel = (event) => {
    if (window.USER_IS_TOUCHING !== true) {
      const prevScrollAmount = scrollAmount;
      const newScrollAmount = prevScrollAmount + event.deltaY;

      const section = document.querySelector('.section__inner');

      if (section) {
        setSectionLength(section.offsetHeight);

        if (props.disableScrollRouteChange === false) {
          if (newScrollAmount > 0 && newScrollAmount < sectionLength) {
            setScrollAmount(newScrollAmount);
          } else if (newScrollAmount < 0) {
            props.history.push('/');
            props.setScrollRouteChangeTimeout();
          } else if (newScrollAmount >= sectionLength) {
            props.history.push('/our-story');
          }
        } else {
          if (props.scrollDirection === -1) {
            setScrollAmount(sectionLength - window.innerHeight)
          }
        }
      }
    }
  }

  const handleScroll = (e) => {
    if (e.target.scrollTop === e.target.scrollHeight - window.innerHeight) {
      if (localScrollAmount < e.target.scrollTop) {
        props.history.push('/our-story');
      }
    }
    if (e.target.scrollTop === 0) {
      if (localScrollAmount > e.target.scrollTop) {
        props.history.push('/');
        props.setScrollRouteChangeTimeout();
      }
    }
    setLocalScrollAmount(e.target.scrollTop);
  }

  const handleSwipedDown = () => {
    //prev
    if (localScrollAmount <= 0) {
      props.history.push('/');
    }
  }

  const handleSwipedUp = () => {
    //next
    const section = document.querySelector('.section__inner');
    if (localScrollAmount > section.scrollHeight - section.parentNode.offsetHeight - 5) {
      props.history.push('/our-story');
    }
  }

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => handleSwipedUp(),
    onSwipedDown: () => handleSwipedDown()
  });

  return (
    <motion.div
      key='about'
      initial={{ y: scrollDirection !== -1 ? window.innerHeight : -window.innerHeight - (sectionLength * 2) }}
      animate={{ y: 0 }}
      exit={{ y: scrollDirection === -1 ? window.innerHeight : -window.innerHeight - sectionLength }}
      transition={{
        duration: 0.9,
        ease: 'easeInOut'
      }}
      style={{
        height: window.USER_IS_TOUCHING === true ? window.innerHeight + 'px' : ''
      }}
      className={`section section--about${props.menuIsOpen === true ? ' section--menu-is-open' : '' }`}
      onWheel={onMouseWheel}
    >
      <div
        className="section__inner"
        onScroll={handleScroll}
        {...swipeHandlers}
        style={{
          top: window.USER_IS_TOUCHING === true ? 0 : 0 - scrollAmount + 'px',
          height: window.USER_IS_TOUCHING === true ? window.innerHeight + 'px' : '',
          overflowY: window.USER_IS_TOUCHING === true ? 'scroll' : 'unset'
        }}
      >
        {
          props.aboutData ?
            props.aboutData[0] ?
              props.aboutData.map(
                (item, i) => <AboutItem {...item} key={i} index={i} scrollAmount={Math.max(scrollAmount, localScrollAmount)} pageBackground={props.pageBackground} />
                )
              :
              ''
            :
            ''
        }

        <p className="scroll-on-text float">
          <Link to="/our-story">
            Scroll on to learn more
          </Link>
        </p>

      </div>
    </motion.div>
  )

}

export default About;
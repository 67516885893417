import { useState, useEffect } from 'react';
import { cockpitUrl } from './cockpit.js';
import { useAuth0 } from "@auth0/auth0-react";
const apiKey = process.env.REACT_APP_API_KEY;

const QuestionSection = (props) => {

  const [userInput, setUserInput] = useState([]);
  const { questionIsActive, chapters, activeChapter, handleActivateFinalPage, handleChangeActiveChapter } = props;
  const currentChapter = chapters[activeChapter - 1];
  const { user } = useAuth0();

  const saveUserEntry = (cockpitUser) => {
    fetch(`${cockpitUrl}/api/collections/save/users?token=${apiKey}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: cockpitUser
      })
    })
    .then(res=>res.json())
    .then(entry => {
    });

    //because the chapter numbering starts from 1, the last chapter will have the same chapter number as the total length of the chapters
    if (activeChapter === chapters.length) {
      //that's the end of the course! Take the user to the final page
      handleActivateFinalPage();
    } else {
      //update the active chapter and deactivate the question dialogue box
      handleChangeActiveChapter(activeChapter + 1);
    }
  }

  const continueWithoutSaving = () => {
    if (activeChapter === chapters.length) {
      //that's the end of the course! Take the user to the final page
      handleActivateFinalPage();
    } else {
      //update the active chapter and deactivate the question dialogue box
      handleChangeActiveChapter(activeChapter + 1);
    }
  }

  useEffect(() => {
    if (userInput.length === 0) {
      if (props.chapters[activeChapter - 1]) {
        if (props.chapters[activeChapter - 1].value.questions) {
          if (props.chapters[activeChapter - 1].value.questions[0]) {
            const questionsArray = [];
            for (let question of props.chapters[activeChapter - 1].value.questions) {
              const questionObject = {
                question: question.value,
                answer: ''
              }
              questionsArray.push(questionObject);
            }
            setUserInput(questionsArray);
          }
        }
      }
    }
  }, [setUserInput, activeChapter, props.chapters, userInput.length]);

  const updateUserEntry = (entry, userInput, chapters, activeChapter, questionIsActive) => {

    const courseId = props.courseData._id;

    if (entry.courses) {
      if (entry.courses[0]) {
        for (let i = 0; i < entry.courses.length; i++) {
          if (entry.courses[i].value.course_id === courseId) {

            const answersArray = entry.courses[i].value.answers;

            if (answersArray[0]) {
              //answers have been saved so let's loop through and check if there are any we need to overwrite
              for (let item of userInput) {

                let hasUserAlreadyFilledThisQuestion = false;

                for (let existingAnswer of answersArray) {
                  if (item.question === existingAnswer.value.question && activeChapter.toString() === existingAnswer.value.chapter) {
                    //if user has already filled this question, let's update it.
                    existingAnswer.value.answer = item.answer;
                    hasUserAlreadyFilledThisQuestion = true;
                  }
                }

                if (hasUserAlreadyFilledThisQuestion === false) {
                  //if user hasn't already filled this question, let's add it!
                  item.chapter = activeChapter.toString();
                  const itemToAddToCockpit = {
                    value: item
                  }
                  answersArray.push(itemToAddToCockpit);
                }
              }



            } else {
              //if no answers have been saved yet, we can just plop these ones right on in.
              for (let item of userInput) {
                item.chapter = activeChapter.toString();
                const itemToAddToCockpit = {
                  value: item
                }
                answersArray.push(itemToAddToCockpit);
              }
            }

            //add our updated answers array back to the entry to save it
            entry.courses[i].value.answers = answersArray;

            //check if this means the user has completed the course and update the answer accordingly.
            if (chapters.length === activeChapter) {
              entry.courses[i].value.complete = true;
            }

            //save it to cockpit
            saveUserEntry(entry);
          }
        }
      }
    }
  }

  const fetchLatestVersionOfUserDataToUpdate = (user, userInput, chapters, activeChapter, questionIsActive) => {

    fetch(`${cockpitUrl}/api/collections/get/users?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        if (response.entries) {
          if (response.entries[0]) {
            for (let entry of response.entries) {
              if (entry.user_id === user.sub) {
                updateUserEntry(entry, userInput, chapters, activeChapter, questionIsActive);
              }
            }
          }
        }
      })
  }

  const onQuestionSubmit = (e) => {
    e.preventDefault();
    fetchLatestVersionOfUserDataToUpdate(user, userInput, chapters, activeChapter, questionIsActive);
  }

  const handleUserInput = (e, i) => {
    const inputElement = e.target;
    const currentUserInput = userInput;
    currentUserInput[i].answer = inputElement.value;
    setUserInput(currentUserInput);
  }

  return (
    <div className="question__wrapper">
      <div className="question__heading">
        {
          questionIsActive === 'before' ?
            <h3 className="question">{chapters[activeChapter - 1].value.questions.question_before}</h3>
            :
            ''
        }
        {
          questionIsActive === 'after' ?
            <h3 className="question">{chapters[activeChapter - 1].value.questions.question_after}</h3>
            :
            ''
        }
      </div>
      <div className="question__answer__wrapper">
        {
          currentChapter.value.questions ?
            <form
              className="question__answer__form"
              onSubmit={onQuestionSubmit}
            >
              {
                currentChapter.value.questions[0] ?
                  currentChapter.value.questions.map(
                    (item, i) => (
                      <div className="questions__set" key={i}>
                        <label className="questions__question" htmlFor={`question ${i}`}>{item.value}</label>
                        <textarea
                          name={`question ${i}`}
                          className="question__answer__input"
                          rows="8"
                          maxLength={640}
                          placeholder="Write your answer here..."
                          onChange={(e) => {
                            handleUserInput(e, i)
                          }}
                          onKeyDown={(e) => {
                            handleUserInput(e, i)
                          }}
                        />
                      </div>
                    )
                  ) : ''
              }
              <input
                name="Save question"
                className="question__answer__submit"
                type="submit"
                value="Save answer" />
            </form> : ''
        }
        <button
          aria-label="Close question and answer popup without saving"
          className="question__answer__close tc"
          onClick={continueWithoutSaving}>continue without saving</button>
      </div>
    </div>
  )
}

export default QuestionSection;
import { cockpitUrl, assetsPath } from '../cockpit.js';
import { useState, useEffect, useCallback } from "react";
import PlayIcon from '../../svg/PlayIcon.js';
import PauseIcon from '../../svg/PauseIcon.js';
import { motion } from 'framer-motion';

const InfographicsGallery = ({ gallery, pageForeground, index }) => {

  const [activeSlide, setActiveSlide] = useState(0);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const [videoSlideIsCurrentSlide, setVideoSlideIsCurrentSlide] = useState(false);
  const [infoIsVisible, setInfoIsVisible] = useState(false);

  const toggleIsPlaying = (e) => {
    const gallery = document.querySelector(`#infographicsGallery${index}`);
    if (gallery) {
      const slides = gallery.children;
      if (slides[activeSlide]) {
        if (slides[activeSlide].querySelector('video')) {
          if (videoIsPlaying === true) {
            slides[activeSlide].querySelector('video').pause();
          } else {
            slides[activeSlide].querySelector('video').play();
          }
        }
      }
    }
    setVideoIsPlaying(!videoIsPlaying)
  }

  const toggleIsInfoVisible = () => {
    setInfoIsVisible(!infoIsVisible);
  }

  useEffect(() => {
    const gallery = document.querySelector(`#infographicsGallery${index}`);
    if (gallery) {
      const slides = gallery.children;
      if (slides[activeSlide]) {
        if (slides[activeSlide].className.indexOf('video') > -1) {
          setVideoSlideIsCurrentSlide(true);
        } else {
          setVideoSlideIsCurrentSlide(false);
        }
      }
    }
  }, [activeSlide, index]);

  const nextSlide = (e) => {
    const gallery = e.target;
    const slidesLength = gallery.children.length;
    if (activeSlide < slidesLength - 1) {
      setActiveSlide(activeSlide + 1);
    } else {
      setActiveSlide(0);
    }

  }

  const prevSlide = (e) => {
    gallery = e.target;
    const slidesLength = gallery.children.length;

    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1);
    } else {
      setActiveSlide(slidesLength - 1);
    }
  }

  const handleClick = (e) => {
    const galleryPosX = e.target.offsetLeft + e.target.parentNode.offsetLeft;
    const galleryWidth = e.target.offsetWidth;

    const gallery = document.querySelector(`#infographicsGallery${index}`);
    if (gallery) {
      const videos = gallery.querySelectorAll('video');
      if (videos[0]) {
        for (let video of videos) {
          video.pause();
          setVideoIsPlaying(false);
        }
      }
    }

    if (e.clientX > galleryPosX + (galleryWidth * 0.33 )) {
      nextSlide(e);
    } else {
      prevSlide(e);
    }
  }

  return (
    <div className="infographics__gallery__wrapper">
      <button
        className="infographics__gallery__wrapper__button--info"
        onClick={
          toggleIsInfoVisible
        }
      >
        {
          infoIsVisible === true ?
            <div className="infographics__gallery__wrapper__button--info__close"></div>
          :
            'i'
        }
      </button>
      {
        videoSlideIsCurrentSlide === true && infoIsVisible === false ?
          <button
            className="infographics__gallery__wrapper__button--play-pause"
            onClick={
              toggleIsPlaying
            }
          >
            {
              videoIsPlaying === true ?
                <PauseIcon pageForeground={pageForeground} />
              :
                <PlayIcon pageForeground={pageForeground} />
            }
          </button> : ''
      }
      <div
        className="infographics__gallery"
        style={{
          backgroundColor: pageForeground
        }}
        id={`infographicsGallery${index}`}
        onClick={handleClick}
      >
        {
          gallery.gallery.map(
            (media, i) => (
              <div
                className={`infographics__gallery__item infographics__gallery__item--${media.field.name}${ activeSlide === i ? ' active' : '' }`}
                key={i}
              >
                {
                  media.field.name === 'image' ?
                    <img
                      className="infographics__gallery__item__media"
                      src={`${assetsPath}/${media.value.path}`}
                      alt={media.value.meta ? media.value.meta.title : "infographic item (click 'info' button for transcript.)"}
                    />
                    :
                    <video
                      className="infographics__gallery__item__media"
                      src={`${assetsPath}/${media.value}`}
                      alt={media.value.meta ? media.value.meta.title : "infographic item (click 'info' button for transcript.)"}
                      onClick={toggleIsPlaying}
                      loop={true}
                    />
                }
              </div>
            )
          )
        }
        {
          infoIsVisible === true ?
            <div className="infographics__gallery__item--info__wrapper">
              <div className="infographics__gallery__item--info">
                {gallery.transcript}
              </div>
            </div> : ''
        }
      </div>
    </div>
  )
}

const Infographics = (props) => {

  const [infographicsData, setInfographicsData] = useState({});

  const getInfographicsData = useCallback(async () => {
    try {
      // because we're async we have to await. This means it can do its own thing and happen whenever without blocking the main thread
      const apiKey = process.env.REACT_APP_API_KEY;

      await fetch(`${cockpitUrl}/api/singletons/get/infographics?token=${apiKey}`)
        .then(response => response.json())
        .then(response => {
          setInfographicsData(response.galleries);
        });


    }
    catch (error) {
      console.log(error)
    }
  }, []);

  useEffect(() => {
    getInfographicsData();
  }, [getInfographicsData]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="section section--infographics" >
      <h2 className="infographics__title">Infographics</h2>
      <div className="infographics__row">
        {
          infographicsData ?
            infographicsData[0] ?
              infographicsData.map(
                (item, i) => (
                  <InfographicsGallery gallery={item.value} pageForeground={props.pageForeground} key={i} index={i} />
                )
              )
            :
            ''
          :
          ''
        }
      </div>
    </motion.div>
  )

}

export default Infographics;
const Spiky = (props) => (
  <svg
    className="spiky"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 404.13 404.13"
    preserveAspectRatio="none"
  >
    <polygon
      className="svg__path"
      points="202.06 2.06 231.33 54.95 278.6 17.29 285.4 77.34 343.49 60.64 326.79 118.73 386.84 125.53 349.18 172.8 402.06 202.06 349.18 231.33 386.84 278.6 326.79 285.4 343.49 343.49 285.4 326.79 278.6 386.84 231.33 349.18 202.06 402.06 172.8 349.18 125.53 386.84 118.73 326.79 60.64 343.49 77.34 285.4 17.29 278.6 54.95 231.33 2.06 202.06 54.95 172.8 17.29 125.53 77.34 118.73 60.64 60.64 118.73 77.34 125.53 17.29 172.8 54.95 202.06 2.06"
    />
  </svg>
);

export default Spiky;
const TwitterIcon = (props) => {
  const { pageForeground } = props;
  return (
    <svg className="socials__icon" xmlns="http://www.w3.org/2000/svg" width="29.506" height="23.978" viewBox="0 0 29.506 23.978">
      <path
        fill={pageForeground}
        d="M29.506,5.087a12.086,12.086,0,0,1-3.477.953,6.063,6.063,0,0,0,2.662-3.349A12.126,12.126,0,0,1,24.846,4.16,6.057,6.057,0,0,0,14.53,9.68,17.185,17.185,0,0,1,2.054,3.356a6.061,6.061,0,0,0,1.872,8.082,6.028,6.028,0,0,1-2.74-.757,6.058,6.058,0,0,0,4.855,6.012,6.067,6.067,0,0,1-2.734.1A6.058,6.058,0,0,0,8.962,21,12.171,12.171,0,0,1,0,23.507a17.137,17.137,0,0,0,9.28,2.719c11.239,0,17.589-9.492,17.206-18.006a12.324,12.324,0,0,0,3.021-3.134Z" transform="translate(0 -2.248)"/>
    </svg>
  )
}

const YouTubeIcon = (props) => {
  const { pageForeground } = props;
  return (
    <svg className="socials__icon" xmlns="http://www.w3.org/2000/svg" width="546.134" height="384" viewBox="0 0 546.134 384">
      <path
        fill={pageForeground}
        d="M549.655,124.083a68.622,68.622,0,0,0-48.284-48.6C458.781,64,288,64,288,64S117.22,64,74.629,75.486a68.624,68.624,0,0,0-48.284,48.6C14.933,166.95,14.933,256.388,14.933,256.388s0,89.438,11.412,132.3c6.281,23.65,24.787,41.5,48.284,47.821C117.22,448,288,448,288,448s170.78,0,213.371-11.486c23.5-6.321,42-24.171,48.284-47.821,11.412-42.867,11.412-132.3,11.412-132.3S561.067,166.95,549.655,124.083ZM232.145,337.591V175.185L374.884,256.39l-142.739,81.2Z" transform="translate(-14.933 -64)" />
    </svg>
  )
}

const PatreonIcon = (props) => {
  const { pageForeground } = props;
  return (
    <svg className="socials__icon" xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29">
      <g data-name="Group 73" transform="translate(-306.943 -80)">
        <rect data-name="Rectangle 27" width="5" height="29" transform="translate(306.943 80)" fill={pageForeground} />
        <circle data-name="Ellipse 26" cx="11" cy="11" r="11" transform="translate(314.943 80)" fill={pageForeground} />
      </g>
    </svg>
  )
}

const InstagramIcon = (props) => {
  const { pageForeground } = props;
  return (
    <svg className="socials__icon" role="img" viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={pageForeground}
        d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/>
    </svg>
  )
}

const AppleMusicIcon = (props) => {
  const { pageForeground } = props;
  return (
    <svg className="socials__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="384" height="512">
      <path
        fill={pageForeground}
        d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
    </svg>
  )
}

const SpotifyIcon = (props) => {
  const { pageForeground } = props;
  return (

    <svg className="socials__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" width="496" height="512">
      <path
        fill={pageForeground}
        d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm100.7 364.9c-4.2 0-6.8-1.3-10.7-3.6-62.4-37.6-135-39.2-206.7-24.5-3.9 1-9 2.6-11.9 2.6-9.7 0-15.8-7.7-15.8-15.8 0-10.3 6.1-15.2 13.6-16.8 81.9-18.1 165.6-16.5 237 26.2 6.1 3.9 9.7 7.4 9.7 16.5s-7.1 15.4-15.2 15.4zm26.9-65.6c-5.2 0-8.7-2.3-12.3-4.2-62.5-37-155.7-51.9-238.6-29.4-4.8 1.3-7.4 2.6-11.9 2.6-10.7 0-19.4-8.7-19.4-19.4s5.2-17.8 15.5-20.7c27.8-7.8 56.2-13.6 97.8-13.6 64.9 0 127.6 16.1 177 45.5 8.1 4.8 11.3 11 11.3 19.7-.1 10.8-8.5 19.5-19.4 19.5zm31-76.2c-5.2 0-8.4-1.3-12.9-3.9-71.2-42.5-198.5-52.7-280.9-29.7-3.6 1-8.1 2.6-12.9 2.6-13.2 0-23.3-10.3-23.3-23.6 0-13.6 8.4-21.3 17.4-23.9 35.2-10.3 74.6-15.2 117.5-15.2 73 0 149.5 15.2 205.4 47.8 7.8 4.5 12.9 10.7 12.9 22.6 0 13.6-11 23.3-23.2 23.3z"/>
    </svg>
  )
}

const FacebookIcon = (props) => {
  const { pageForeground } = props;
  return (
    <svg className="socials__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="512" height="512">
      <path
        fill={pageForeground}
        d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
    </svg>
  )
}

export { TwitterIcon, YouTubeIcon, InstagramIcon, AppleMusicIcon, SpotifyIcon, FacebookIcon, PatreonIcon };
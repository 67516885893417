import { useState } from 'react';
import { MenuItem } from './NavLinks.js';
import { LoginLogoutButton } from './LoginLogoutButton.js';
import { Socials } from './views/Contact.js';

const menuItems = [
  {
    text: 'About',
    href: '/about'
  },
  {
    text: 'Our Story',
    href: '/our-story'
  },
  {
    text: 'Connect',
    href: '/connect'
  },
  {
    text: 'Learn',
    href: '/learn'
  },
  {
    text: 'Contact',
    href: '/contact'
  },
  {
    text: 'Courses',
    href: '/courses'
  },
  // {
  //   text: 'Infographics',
  //   href: '/infographics'
  // },
  // {
  //   text: 'Youtube',
  //   href: '/youtube'
  // },
  {
    text: 'Policies',
    href: '/privacy-policy'
  }
]

const MenuButton = (props) => {

  const { pageBackground, pageForeground, handleMenuButtonClick, menuIsOpen } = props;

  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }

  const handleFocus = () => {
    setIsFocused(true);
  }

  const handleBlur = () => {
    setIsFocused(false);
  }

  return (
    <button
      id="menuButton"
      aria-label={`${menuIsOpen === true ? 'Close' : 'Open'} Menu`}
      style={{
        backgroundColor: isHovered === false && isFocused === false ? pageBackground : pageForeground,
        color: isHovered === false && isFocused === false ? pageForeground : pageBackground,
        borderColor: pageForeground
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleMenuButtonClick}
    >{ menuIsOpen === true ? `Close` : `Menu`}</button>
  )
}

const Menu = (props) => {

  const { menuIsOpen, pageBackground, pageForeground, route } = props;

  return (
    <nav
      id="menu"
      className={`full-height ${menuIsOpen === true ? 'menu--open' : 'menu--closed'}`}
      style={{
        color: pageForeground
      }}
    >
      <div
        className="menu__inner"
        style={{
          color: pageForeground,
          borderColor: pageForeground
        }}>
        {
          menuIsOpen === true ?
            <div className="menu__socials__container">
              <Socials {...props} />
            </div> : ''
        }
        {
          menuIsOpen === true ?
            <ul className="menu__list">
              {
                menuItems.map(
                  (menuItem, i) => (
                    <li className="menu__list__item" key={i}>
                      <MenuItem {...menuItem} pageBackground={pageBackground} pageForeground={pageForeground} route={route} />
                    </li>
                  )
                )
              }
              <li className="menu__list__item">
                <LoginLogoutButton {...props} />
              </li>
            </ul> : ''
        }
        {
          menuIsOpen === true ?
            <a className="site-credit" href="http://dxr.zone" target="_blank" rel="noreferrer">Site by DXR Zone</a> : ''
        }
      </div>
    </nav>
  )
}

export { Menu, MenuButton };
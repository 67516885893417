import { useState, useEffect, useCallback } from "react";
import { assetsPath, cockpitUrl } from '../cockpit.js';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Wiggly from "../../svg/Wiggly.js";

const CoursesThumbnail = (props) => {
  return (
    <div className="course__thumbnail float">
      <img
        className="course__thumbnail__img"
        src={assetsPath + '/' + props.image.path}
        alt={props.image.meta ? props.image.meta.title : `Thumbnail image for course, ${props.title}`}
      />
    </div>
  )
}

const CoursesCourseUserNotLoggedIn = (props) => {
  return (
    <div className="courses__course__links__wrapper">
      <Link
        to={`/courses/${props._id}`}
        className="courses__course__link"
      >
        <div className="courses__course__link__inner">Learn more</div>
      </Link>
    </div>
  )
}

const CoursesCourseUserLoggedInButNotPurchased = (props) => {
  return (
    <Link
      to={`/courses/${props._id}`}
      className="courses__course__link"
    >
      <div className="courses__course__link__inner">Learn more</div>
    </Link>
  )
}

const CoursesCourseUserHasPurchased = (props) => {
  return (
      <Link to={`/courses/${props._id}`}
        className="courses__course__link"
      >
        <div className="courses__course__link__inner"
        >Go to {props.title} &rarr;
        </div>
      </Link>
  )
}

const CoursesCourse = (props) => {

  //for testing purposes only
  const [userHasPurchasedCourse, setUserHasPurchasedCourse] = useState(false);

  const { users } = props;
  const { isAuthenticated, isLoading, user } = useAuth0();

  useEffect(() => {
    if (users[0] && user) {
      for (let userOnCourse of users) {
        if (userOnCourse.value) {
          if (userOnCourse.value.user_id === user.sub) {
            setUserHasPurchasedCourse(true);
          }
        }
      }
    }
  }, [userHasPurchasedCourse, users, user]);

  return (
    <li className="courses__course">
      {
        props ?
          <div className="courses__course__inner">
            {
              userHasPurchasedCourse === true &&
              <div className="courses__course__purchased float--2">
                <div className="courses__course__purchased__inner">
                  <Wiggly />
                  <div className="courses__course__purchased__text__wrapper">
                    <p className="courses__course__purchased__text">You’ve bought this course!</p>
                  </div>
                </div>
              </div>
            }
            <div className="courses__course__image-and-title__wrapper">
              { props.image ?
                <CoursesThumbnail {...props} />
                :
                ''
              }
              <div
                className="courses__course__title__wrapper float--2"
                style={{
                  backgroundColor: props.pageBackground
                }}
              >
                <h2 className="courses__course__title">{props.title}</h2>
              </div>
            </div>
            <div className="courses__course__meta__wrapper">
              <div className="courses__course__info__column">
                <div className="courses__course__info__wrapper float--3">
                  <div className="courses__course__info">
                    <div className="courses__course__info__text">
                      {props.synopsis}
                    </div>
                  </div>
                </div>
              </div>
              {
                !isLoading && (
                  <div className="courses__course__access__wrapper">
                    {
                      isAuthenticated && (
                        <div className="courses__course__access_inner float">
                          {
                            userHasPurchasedCourse === true ?
                              <CoursesCourseUserHasPurchased {...props} />
                              :
                              <CoursesCourseUserLoggedInButNotPurchased {...props} />
                          }
                        </div>
                      )
                    }
                    {
                      !isAuthenticated && (
                        <div className="courses__course__access_inner">
                          <CoursesCourseUserNotLoggedIn {...props} />
                        </div>
                      )
                    }
                  </div>
                )
              }
            </div>
          </div>
        :
        ''
      }
    </li>
  )
}

const Courses = (props) => {

  const [coursesData, setCoursesData] = useState({});

  const getCoursesData = useCallback(async () => {
    try {
      // because we're async we have to await. This means it can do its own thing and happen whenever without blocking the main thread
      const apiKey = process.env.REACT_APP_API_KEY;

      await fetch(`${cockpitUrl}/api/collections/get/courses?token=${apiKey}`)
        .then(response => response.json())
        .then(response => {
          setCoursesData(response);
        })

    }
    catch (error) {
      console.log(error)
    }
  }, []);

  useEffect(() => {
    getCoursesData();
  }, [getCoursesData]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.4,
        ease: 'easeInOut'
      }}
      className={`section section--courses${props.menuIsOpen === true ? ' section--menu-is-open' : '' }`}
    >
      <ul className="courses__list">
        {
          coursesData.entries ?
            coursesData.entries.map(
              (item, i) => (
                <CoursesCourse key={i} {...item} {...props} />
              )
            )
          :
          ''
        }
      </ul>
    </motion.div>
  )

}

export default Courses;
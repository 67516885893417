const WigglyLine = () => (

    <svg
      className="line--wiggly"
      width="152.66"
      height="24.03"
      viewBox="0 0 152.66 24.03"
    >
      <path className="cls-1" d="M10.9,12A11,11,0,0,0,0,1" />
      <path className="cls-1" d="M32.71,12a11,11,0,0,1-10.9,11" />
      <path className="cls-1" d="M10.9,12A11,11,0,0,0,21.81,23" />
      <path className="cls-1" d="M32.71,12A11,11,0,0,1,43.62,1" />
      <path className="cls-1" d="M54.52,12A11,11,0,0,0,43.62,1" />
      <path className="cls-1" d="M76.33,12a11,11,0,0,1-10.9,11" />
      <path className="cls-1" d="M54.52,12A11,11,0,0,0,65.43,23" />
      <path className="cls-1" d="M76.33,12A11,11,0,0,1,87.23,1" />
      <path className="cls-1" d="M98.14,12A11,11,0,0,0,87.23,1" />
      <path className="cls-1" d="M120,12A11,11,0,0,1,109,23" />
      <path className="cls-1" d="M98.14,12A11,11,0,0,0,109,23" />
      <path className="cls-1" d="M120,12a11,11,0,0,1,10.9-11" />
      <path className="cls-1" d="M141.75,12a11,11,0,0,0-10.9-11" />
      <path className="cls-1" d="M141.75,12a11,11,0,0,0,10.91,11" />
    </svg>
);

const WigglyLineVertical = () => (
  <svg
    className="line--wiggly--vertical"
    xmlns="http://www.w3.org/2000/svg"
    width="24.03"
    height="152.659"
    viewBox="0 0 24.03 152.659"
  >
  <g id="Group_112" data-name="Group 112" transform="translate(718.502 -61.479) rotate(90)">
    <path id="Path_9" d="M72.383,706.487a10.954,10.954,0,0,0-10.9-11.015" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Path_10" d="M94.191,706.487a10.954,10.954,0,0,1-10.9,11.015" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Path_11" d="M72.383,706.487a10.954,10.954,0,0,0,10.9,11.015" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Path_12" d="M94.191,706.487a10.954,10.954,0,0,1,10.9-11.015" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Path_13" d="M116,706.487a10.954,10.954,0,0,0-10.9-11.015" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Path_14" d="M137.808,706.487A10.954,10.954,0,0,1,126.9,717.5" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Path_15" d="M116,706.487A10.954,10.954,0,0,0,126.9,717.5" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Path_16" d="M137.808,706.487a10.954,10.954,0,0,1,10.9-11.015" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Path_17" d="M159.616,706.487a10.954,10.954,0,0,0-10.9-11.015" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Path_18" d="M181.425,706.487a10.954,10.954,0,0,1-10.9,11.015" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Path_19" d="M159.616,706.487a10.955,10.955,0,0,0,10.9,11.015" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Path_20" d="M181.425,706.487a10.954,10.954,0,0,1,10.9-11.015" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Path_21" d="M203.233,706.487a10.954,10.954,0,0,0-10.9-11.015" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
    <path id="Path_22" d="M203.233,706.487a10.954,10.954,0,0,0,10.9,11.015" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="2"/>
  </g>
</svg>

);

export { WigglyLine, WigglyLineVertical };
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter
} from 'react-router-dom'
import './css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="climate-in-colour.eu.auth0.com"
      clientId="1F4O66bpO1rCIQ59XWLMW0rOijLBN202"
      audience="https://climate-in-colour.eu.auth0.com/api/v2/"
      scope="read:current_user update:current_user_metadata"
      redirectUri={`${window.location.origin}/courses`}
      >
      <BrowserRouter basename='/'>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useState, useEffect } from "react";
import { motion } from 'framer-motion';
import { assetsPath } from '../cockpit.js';
import AudioPlayer from '../AudioPlayer.js';
import Wiggly from '../../svg/Wiggly.js';
import Spiky from '../../svg/Spiky.js';
import YoutubeVideo from '../YoutubeVideo.js';
import { useSwipeable } from 'react-swipeable';
import parse, { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';

const ConnectIntro = ({ intro }) => (
  <div className="connect__intro__row float">
    <div className="connect__intro__wrapper">
      <p className="connect__intro font--subheading tc">
        {intro}
      </p>
    </div>
  </div>
);

const ConnectHeading = () => (
  <h2 className="connect__heading font--heading--large tc">
    Connect
  </h2>
);

const ConnectItemHeading = ({pageBackground, children}) => (
  <div className="connect__item__heading__wrapper float--2"
    style={{
      backgroundColor: pageBackground
    }}
  >
    <h3 className="connect__item__heading">{children}</h3>
  </div>
)

const ConnectItemInfo = ({ pageBackground, children }) => (
  <div className="connect__item__info__wrapper float">
    <div
      className="connect__item__info__outer"
      style={{
        backgroundColor: pageBackground
      }}
    >
      <p className="connect__item__info">
        {children}
      </p>
    </div>
  </div>
)

const ConnectItemLink = ({ linkUrl, linkText }) => (
  <div className="connect__link__wrapper float">
    <a className="connect__link" target="_blank" href={linkUrl} rel="noreferrer">
      <Wiggly />
      <p className="connect__link__inner">{linkText}</p>
    </a>
  </div>
)

const ConnectYoutube = (props) => (
  <div className="connect__item__inner">
    <ConnectItemHeading pageBackground={props.pageBackground}>Youtube</ConnectItemHeading>
    <div className="connect__youtube__wrapper">
      <YoutubeVideo url={props.value.video_url} />
    </div>
    <ConnectItemInfo pageBackground={props.pageBackground}>{props.value.info}</ConnectItemInfo>
    <ConnectItemLink linkUrl={props.value.link_url} linkText={props.value.link_text} />
  </div>
)

const PatreonMembership = (props) => {
  return (
    <div className="connect__patreon__membership__wrapper">
      <div className="connect__patreon__membership__price__wrapper">
        <div className="connect__patreon__membership__price__inner">
          <Spiky />
          <h3 className="connect__patreon__membership__price">£{props.value.price}</h3>
        </div>
      </div>
      <div className="connect__patreon__membership__features__wrapper">
        <ul className="connect__patreon__membership__features__list">
          {
            props.value.features ?
              props.value.features.map(
                (item, i) => (
                  <li
                    className="connect__patreon__membership__features__list__item"
                    key={i}
                  >{item.value}</li>
                )
              )
            :
              ''
            }
        </ul>
      </div>
    </div>
  )
}

const ConnectPatreon = (props) => {

  const { audio, info, memberships, link_url, link_text } = props.value;

  return (
    <div className="connect__item__inner">
      <ConnectItemHeading pageBackground={props.pageBackground}>Patreon</ConnectItemHeading>
      <div className="connect__patreon__wrapper">
        <div className="connect__patreon__inner">
          <AudioPlayer audio={`${assetsPath}/${audio}`} pageBackground={props.pageBackground} pageForeground={props.pageForeground} />
          <ConnectItemInfo pageBackground={props.pageBackground}>{info}</ConnectItemInfo>
          <div className="connect__patreon__memberships">
            {
              memberships.map(
                (item, i) => <PatreonMembership {...item} key={i} />
              )
            }
          </div>
          <ConnectItemLink linkUrl={link_url} linkText={link_text} />
        </div>
      </div>
    </div>
  )
}

const InstagramImage = (props) => (
  <div className="connect__instagram__image__row">
    <div className="connect__instagram__image__wrapper">
      { props.value ?
        <img
          className="connect__instagram__image"
          src={`${assetsPath}/${props.value.path}`}
          alt={props.value.meta ? props.value.meta.title : "Image from the Climate in Colour Instagram account" }
        /> : ''
      }
    </div>
  </div>
)

const ConnectInstagram = (props) => {

  const { images, link_url, info, link_text } = props.value;

  return (
    <div className="connect__item__inner">
      <ConnectItemHeading pageBackground={props.pageBackground}>Instagram</ConnectItemHeading>
      <div className="connect__instagram__wrapper">
        <div className="connect__instagram__inner">
          <ConnectItemInfo pageBackground={props.pageBackground}>{info}</ConnectItemInfo>
          <div className="connect__item--instagram__images">
            {
              images ?
                images.map(
                  (item, i) => <InstagramImage {...item} key={i} />
                )
              :
                ''
            }
          </div>
          <ConnectItemLink linkUrl={link_url} linkText={link_text} />
        </div>
      </div>
    </div>
  )
}

const ConnectNewsletter = (props) => {

  const { newsletter, link_url, info, link_text } = props.value;
  const [newsletterIsExpanded, setNewsletterIsExpanded] = useState(false);

  const newsletterHTML = newsletter;

  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) {
        return;
      }
      if (attribs.href) {
        return <span tabIndex="-1">{domToReact(children, options)}</span>;
      }
    }
  };

  const handleNewsletterButtonClick = () => {
    setNewsletterIsExpanded(!newsletterIsExpanded);
  }

  return (
    <div className="connect__item__inner">
      <ConnectItemHeading pageBackground={props.pageBackground}>Newsletter</ConnectItemHeading>
      <div className="connect__newsletter__wrapper">
        <div className="connect__newsletter__inner">
          <ConnectItemInfo pageBackground={props.pageBackground}>{info}</ConnectItemInfo>
          <div className={`connect__newsletter__content__wrapper${newsletterIsExpanded === true ? ' expanded' : ''}`}>
            <div className="connect__newsletter__content__inner">
              <div className="connect__newsletter__content">{newsletterIsExpanded === true ? parse(newsletterHTML) : parse(newsletterHTML, options)}</div>
            </div>
            {
              newsletterIsExpanded === true ?
                <button
                  aria-label="Hide Newsletter"
                  className="connect__newsletter__button--expand-contract connect__newsletter__button--expand-contract--top"
                  onClick={handleNewsletterButtonClick}
                >
                <div className="connect__newsletter__button--expand-contract__inner">
                  <div className="connect__newsletter__button--expand-contract__line--horizontal"></div>
                </div>
              </button> : ''
            }
            <button
              aria-label={`${newsletterIsExpanded === true ? 'Hide' : 'Show'} Newsletter`}
              className="connect__newsletter__button--expand-contract"
              onClick={handleNewsletterButtonClick}
            >
              <div className="connect__newsletter__button--expand-contract__inner">
                <div className={`connect__newsletter__button--expand-contract__line--vertical${newsletterIsExpanded === true ? ' transparent' : ''}`}></div>
                <div className="connect__newsletter__button--expand-contract__line--horizontal"></div>
              </div>
            </button>
          </div>
          <ConnectItemLink linkUrl={link_url} linkText={link_text} />
        </div>
      </div>
    </div>
  )
}

const ConnectItem = (props) => (
  <li className={`connect__item connect__item--${props.field.name}`}>
    { props.field.name === 'youtube' ? <ConnectYoutube {...props} /> : '' }
    { props.field.name === 'patreon' ? <ConnectPatreon {...props} /> : '' }
    { props.field.name === 'instagram' ? <ConnectInstagram {...props} /> : '' }
    { props.field.name === 'newsletter' ? <ConnectNewsletter {...props} /> : '' }
  </li>
)

const Connect = (props) => {

  const [scrollAmount, setScrollAmount] = useState(0);
  const [connectInitialised, setConnectInitialised] = useState(false);
  const [sectionLength, setSectionLength] = useState(0);
  const { scrollDirection } = props;
  const [localScrollAmount, setLocalScrollAmount] = useState(0)

  useEffect(() => {
    if (connectInitialised === false) {
      setConnectInitialised(true);
      const direction = props.scrollDirection;
      if (direction === -1) {
        const section = document.querySelector('.section__inner');
        if (section) {
          const sectionHeight = section.offsetHeight;
          setScrollAmount(sectionHeight - window.innerHeight);
          setLocalScrollAmount(sectionHeight - window.innerHeight);
          section.scrollTop = section.scrollHeight - section.offsetHeight - 1;
        }
      }
    }

    const section = document.querySelector('.section__inner');
    if (section) {
      setSectionLength(section.offsetHeight);
    }

  }, [connectInitialised, props.scrollDirection, setSectionLength]);

  const onMouseWheel = (event) => {
    if (window.USER_IS_TOUCHING !== true) {
      const prevScrollAmount = scrollAmount;
      const newScrollAmount = prevScrollAmount + event.deltaY;

      const section = document.querySelector('.section__inner');

      if (section) {
        setSectionLength(section.offsetHeight);

        if (props.disableScrollRouteChange === false) {
          if (newScrollAmount > 0 && newScrollAmount < sectionLength) {
            setScrollAmount(newScrollAmount);
          } else if (newScrollAmount < 0) {
            props.history.push('/our-story');
            props.setScrollRouteChangeTimeout();
          } else if (newScrollAmount >= sectionLength) {
            props.history.push('/learn');
          }
        } else {
          if (props.scrollDirection === -1) {
            setScrollAmount(sectionLength)
          }
        }
      }
    }
  }

  const handleScroll = (e) => {
    if (e.target.scrollTop === e.target.scrollHeight - window.innerHeight) {
      if (localScrollAmount < e.target.scrollTop) {
        props.history.push('/learn');
      }
    }
    if (e.target.scrollTop === 0) {
      if (localScrollAmount > e.target.scrollTop) {
        props.history.push('/our-story');
        props.setScrollRouteChangeTimeout();
      }
    }
    setLocalScrollAmount(e.target.scrollTop);
  }

  const handleSwipedDown = () => {
    //prev
    if (localScrollAmount <= 0) {
      props.history.push('/our-story');
    }
  }

  const handleSwipedUp = () => {
    //next
    const section = document.querySelector('.section__inner');
    if (localScrollAmount > section.scrollHeight - section.parentNode.offsetHeight - 5) {
      props.history.push('/learn');
    }
  }

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => handleSwipedUp(),
    onSwipedDown: () => handleSwipedDown()
  });

  return (

    <motion.div
      key='connect'
      initial={{ y: scrollDirection !== -1 ? window.innerHeight : -window.innerHeight - sectionLength }}
      animate={{ y: 0 }}
      exit={{ y: scrollDirection === -1 ? window.innerHeight : -window.innerHeight - sectionLength }}
      transition={{
        duration: 0.9,
        ease: 'easeInOut'
      }}
      style={{
        height: window.USER_IS_TOUCHING === true ? window.innerHeight + 'px' : ''
      }}
      className={`section section--connect${props.menuIsOpen === true ? ' section--menu-is-open' : '' }`}
      onWheel={onMouseWheel}
    >
      <div
        className="section__inner section--connect__inner"
        onScroll={handleScroll}
        {...swipeHandlers}
        style={{
          top: window.USER_IS_TOUCHING === true ? 0 : 0 - scrollAmount + 'px',
          height: window.USER_IS_TOUCHING === true ? window.innerHeight + 'px' : '',
          overflowY: window.USER_IS_TOUCHING === true ? 'scroll' : 'unset'
        }}
      >
        <ConnectHeading />
        <ConnectIntro intro={props.connectData.intro} />
        <ul className="connect__items">
          {
            props.connectData.socials ?
              props.connectData.socials.map(
                (item, i) => <ConnectItem {...item} {...props} key={i} />
              )
            :
            ''
          }
        </ul>
        <p className="scroll-on-text float">
          <Link to="/learn">
            Scroll on for educational resources
          </Link>
        </p>
      </div>
    </motion.div>
  )

}

export default Connect;
import { useState } from "react";
import { TwitterIcon, YouTubeIcon, SpotifyIcon, AppleMusicIcon, InstagramIcon, FacebookIcon, PatreonIcon } from "../../svg/SocialIcons.js";
import { motion } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';

const Socials = (props) => {

  const { socials } = props.contactData;

  return (
    <div className="socials__outer">
      <ul className="socials__wrapper"
        style={{
          backgroundColor: props.pageBackground
        }}
      >
        {
          socials ?
            socials.map(
              (item, i) => (
                <li className="socials__list__item" key={i}>
                  <a target="_blank" rel="noreferrer" className="socials__link" href={item.value}>
                    <span className="socials__link__text">{
                      item.field.name === 'custom' ?
                        item.value.icon.meta ?
                          item.value.icon.meta.title
                          :
                          'Link to social media'
                        :
                      item.field.name
                    }</span>
                    {
                      item.field.name === 'twitter' ?
                        <TwitterIcon pageForeground={props.pageForeground} />
                      : ''
                    }
                    {
                      item.field.name === 'youtube' ?
                        <YouTubeIcon pageForeground={props.pageForeground} />
                      : ''
                    }
                    {
                      item.field.name === 'spotify' ?
                        <SpotifyIcon pageForeground={props.pageForeground} />
                      : ''
                    }
                    {
                      item.field.name === 'apple_music' ?
                        <AppleMusicIcon pageForeground={props.pageForeground} />
                      : ''
                    }
                    {
                      item.field.name === 'instagram' ?
                        <InstagramIcon pageForeground={props.pageForeground} />
                      : ''
                    }
                    {
                      item.field.name === 'facebook' ?
                        <FacebookIcon pageForeground={props.pageForeground} />
                      : ''
                    }
                    {
                      item.field.name === 'patreon' ?
                        <PatreonIcon pageForeground={props.pageForeground} />
                      : ''
                    }
                    {
                      item.field.name === 'custom' ?
                        <img alt={`logo for ${item.value.icon.meta ? item.value.icon.meta.title : 'social link'}`} className="socials__icon" src={ item.value.icon ? item.value.icon.path : ''} />
                      : ''
                    }
                  </a>
                </li>
              )
            )
          : ''
        }
      </ul>
    </div>
  )
}

const Contact = (props) => {

  const [scrollAmount, setScrollAmount] = useState(0);
  const [servicesAreVisible, setServicesAreVisible] = useState(false);
  const [localScrollAmount, setLocalScrollAmount] = useState(0)

  const onMouseWheel = (event) => {
    if (window.USER_IS_TOUCHING !== true) {
      const prevScrollAmount = scrollAmount;
      const newScrollAmount = prevScrollAmount + event.deltaY;

      const section = document.querySelector('.section__inner');

      let sectionLength = 0;
      if (section) {
        sectionLength = section.offsetHeight;

        if (newScrollAmount > 0 && newScrollAmount <= sectionLength - window.innerHeight) {
          setScrollAmount(newScrollAmount);
        } else if (newScrollAmount < 0) {
          props.history.push('/learn');
          props.setScrollRouteChangeTimeout();
        }
      }
    }
  }

  const toggleServicesAreVisible = () => {
    setServicesAreVisible(!servicesAreVisible);
  }

  const handleScroll = (e) => {
    if (e.target.scrollTop === 0) {
      if (localScrollAmount > e.target.scrollTop) {
        props.history.push('/learn');
        props.setScrollRouteChangeTimeout();
      }
    }
    setLocalScrollAmount(e.target.scrollTop);
  }

  const handleSwipedDown = () => {
    //prev
    if (localScrollAmount <= 0) {
      props.history.push('/learn');
    }
  }

  const swipeHandlers = useSwipeable({
    onSwipedDown: () => handleSwipedDown()
  });

  const { contactData } = props;

  return (
    <motion.div
      key='learn'
      initial={{ y: window.innerHeight }}
      animate={{ y: 0 }}
      exit={{ y: window.innerHeight }}
      transition={{
        duration: 0.9,
        ease: 'easeInOut'
      }}
      style={{
        height: window.USER_IS_TOUCHING === true ? window.innerHeight + 'px' : ''
      }}
      className={`section section--contact${props.menuIsOpen === true ? ' section--menu-is-open' : '' }`}
      onWheel={onMouseWheel}
    >
      <Socials {...props} />
      <div
        className="section__inner"
        onScroll={handleScroll}
        {...swipeHandlers}
        style={{
          top: window.USER_IS_TOUCHING === true || window.innerWidth < 500 ? 0 : 0 - scrollAmount + 'px',
          height: window.USER_IS_TOUCHING === true ? window.innerHeight + 'px' : '',
          overflowY: window.USER_IS_TOUCHING === true ? 'scroll' : 'unset'
        }}
      >
        <div className="contact__inner">
          <h2 className="contact__title float--2">Get in Touch</h2>
          <div className="contact__row">
            <div className="contact__column contact__column--brands">
              <div className="contact__item--brands float--2">
                <a
                  className="contact__email contact__email--brands"
                  href={contactData.brands_and_partnerships_email ? `mailto:${contactData.brands_and_partnerships_email}` : '#'}
                >
                  Brands and Partnerships
                </a>
                <button
                  className="services__heading"
                  aria-label={`${servicesAreVisible === true ? 'Hide' : 'Show'} list of services`}
                  onClick={toggleServicesAreVisible}
                >
                  Services
                </button>
                {
                  servicesAreVisible === true ?
                    <div className="services__wrapper">
                      <ul className="services__list">
                        {
                          contactData.services ?
                            contactData.services.map(
                              (item, i) => (
                                <li className="services__item" key={i}>{item.value}</li>
                              )
                            )
                            : ''
                        }
                      </ul>
                    </div>
                  : ''
                }
              </div>
            </div>
            <div className="contact__column contact__column--general">
              <div className="contact__item--general float">
                <a
                  className="contact__email contact__email--general"
                  href={contactData.general_email ? `mailto:${contactData.general_email}` : '#'}
                >
                  General Enquiries
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )

}

export { Socials, Contact };
import React, { useState, useEffect, useCallback } from 'react';
import {
  Route,
  Switch
} from 'react-router-dom'
import './css/tachyons.min.css';
import './css/App.css';

import Header from './js/Header.js';
import { NavLinks } from './js/NavLinks.js';
import { Menu, MenuButton } from './js/Menu.js';

import LandingPage from './js/views/LandingPage.js';
import About from './js/views/About.js';
import OurStory from './js/views/OurStory.js';
import Connect from './js/views/Connect.js';
import Learn from './js/views/Learn.js';
import { Contact } from './js/views/Contact.js';
import Courses from './js/views/Courses.js';
import Course from './js/views/Course.js';
import Infographics from './js/views/Infographics.js';
import YouTube from './js/views/YouTube.js';
import PrivacyPolicy from './js/views/PrivacyPolicy.js';
import { cockpitUrl } from './js/cockpit.js';
import colors from './js/colors.js';
import { AnimatePresence } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import MotionButton from './MotionButton.js';

const onWindowResize = () => {
  const fullHeightElements = document.querySelectorAll('.full-height');
  if (fullHeightElements[0]) {
    for (let fullHeightElement of fullHeightElements) {
      fullHeightElement.style.height = window.innerHeight;
    }
  }
}

const Content = (props) => {

  const onFirstTouch = () => {
    window.USER_IS_TOUCHING = true;
  }

  const router = props.location;
  const [scrollDirection, setScrollDirection] = useState(0);
  const [pageBackground, setBackgroundColor] = useState(colors.orange);
  const [pageForeground, setforegroundColor] = useState(colors.black);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [motionIsEnabled, setMotionIsEnabled] = useState(true);

  const [aboutData, setAboutData] = useState([]);
  const [storyData, setStoryData] = useState([]);
  const [connectData, setConnectData] = useState({});
  const [learnData, setLearnData] = useState({});
  const [contactData, setContactData] = useState({});
  const [initialised, setInitialised] = useState(false);
  const [uiIsHidden, setUIIsHidden] = useState(false);
  const [disableScrollRouteChange, setDisableScrollRouteChange] = useState(false);

  const [pageName, setPageName] = useState('');

  let scrollRouteChangeTimeout;

  const onScrollRouteChangeTimeout = () => {
    setDisableScrollRouteChange(false);
  }

  const setScrollRouteChangeTimeout = () => {
    clearTimeout(scrollRouteChangeTimeout);
    setDisableScrollRouteChange(true);
    setTimeout(onScrollRouteChangeTimeout, 900);
  }

  let uiTimeout;

  const toggleMotionIsEnabled = () => {
    setMotionIsEnabled(!motionIsEnabled);
  }

  const showUI = () => {
    clearTimeout(uiTimeout);
    setUIIsHidden(false);
    if (router.pathname.indexOf('courses/') > -1) {
      // uiTimeout = setTimeout(hideUI, 12000);
    }
  }

  const onLinkClick = (e) => {
    let target = e.target;
    if (!e.target.href) {
      target = target.closest('a');
    }
    if (target) {
      if (target.href) {
        setScrollDirection(0)
      }
    }
  }

  const getSiteData = async () => {
    try {
      // because we're async we have to await. This means it can do its own thing and happen whenever without blocking the main thread
      const apiKey = process.env.REACT_APP_API_KEY;

      const aboutUrl = `${cockpitUrl}/api/singletons/get/about?token=${apiKey}`;
      const aboutResponse = await fetch(aboutUrl);
      const aboutResponseData = await aboutResponse.json();
      setAboutData(aboutResponseData.about);

      const storyResponse = await fetch(`${cockpitUrl}/api/singletons/get/story?token=${apiKey}`);
      const storyResponseData = await storyResponse.json();
      setStoryData(storyResponseData.story);

      const connectResponse = await fetch(`${cockpitUrl}/api/singletons/get/connect?token=${apiKey}`);
      const connectResponseData = await connectResponse.json();
      setConnectData(connectResponseData);

      const learnResponse = await fetch(`${cockpitUrl}/api/singletons/get/learn?token=${apiKey}`);
      const learnResponseData = await learnResponse.json();
      setLearnData(learnResponseData);

      const contactResponse = await fetch(`${cockpitUrl}/api/singletons/get/contact?token=${apiKey}`);
      const contactResponseData = await contactResponse.json();
      setContactData(contactResponseData);

    }
    catch (error) {
      console.log(error)
    }
  }

  const onRouteChange = useCallback(() => {

    if (router.pathname === '/about'
      || router.pathname === '/our-story'
      || router.pathname === '/contact'
      || router.pathname === '/youtube'
      || router.pathname === '/infographics') {
      setBackgroundColor(colors.teal);
      setforegroundColor(colors.white);
    } else {
      setBackgroundColor(colors.orange);
      setforegroundColor(colors.black);
    }
    document.body.style.backgroundColor = pageBackground;
    document.body.style.color = pageForeground;
    setMenuIsOpen(false);

    const newPageName = props.location.pathname.indexOf('courses/') > -1 ? 'course' : props.location.pathname.replace('/', '');
    setPageName(newPageName);

    const pageNameNoHyphens = newPageName.replace(/-/g, ' ');

    let pageTitle = '';
    if (pageNameNoHyphens.indexOf(' ') > -1) {
      const pageNameSplit = pageNameNoHyphens.split(' ');
      for (let word of pageNameSplit) {
        const titleCaseWord = word[0].toUpperCase() + word.substring(1) + ' ';
        pageTitle += titleCaseWord;
      }
    } else {
      if (pageNameNoHyphens[0]) {
        pageTitle = pageNameNoHyphens[0].toUpperCase() + pageNameNoHyphens.substring(1) + ' ';
      } else {
        pageTitle = 'Home ';
      }
    }

    document.title = pageTitle + "| Climate in Colour";
    onWindowResize();

  }, [router.pathname, pageBackground, pageForeground, props.location.pathname])

  useEffect(() => {
    if (initialised === false) {
      setInitialised(true);
      getSiteData();
      window.addEventListener('resize', onWindowResize);
    }
    onRouteChange();
  },
    [router.pathname, initialised, onRouteChange]);

  let wheelTimeout;

  const onWheelTimeout = () => {
    setScrollDirection(0);
    onWindowResize();
  }

  const handleWheel = (e) => {
    if (e.deltaY) {
      clearTimeout(wheelTimeout);
      let newScrollDirection = e.deltaY > 0 ? 1 : -1;
      newScrollDirection = e.deltaY === 0 ? 0 : newScrollDirection;
      setScrollDirection(newScrollDirection);
      wheelTimeout = setTimeout(onWheelTimeout, 900);
    }
  }

  const handleSwipedUp = () => {
    clearTimeout(wheelTimeout);
    setScrollDirection(1);
    wheelTimeout = setTimeout(onWheelTimeout, 1200);
  }

  const handleSwipedDown = () => {
    clearTimeout(wheelTimeout);
    setScrollDirection(-1);
    wheelTimeout = setTimeout(onWheelTimeout, 1200);
  }

  const handleSwiping = (e) => {
    clearTimeout(wheelTimeout);
    let newScrollDirection = e.deltaY < 0 ? 1 : -1;
    newScrollDirection = e.deltaY === 0 ? 0 : newScrollDirection;
    setScrollDirection(newScrollDirection);
    wheelTimeout = setTimeout(onWheelTimeout, 1200);
  }

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => handleSwipedUp(),
    onSwipedDown: () => handleSwipedDown(),
    onSwiping: (e) => handleSwiping(e)
  });

  const handleMenuButtonClick = (e) => {
    const newMenuIsOpen = menuIsOpen === true ? false : true;
    setMenuIsOpen(newMenuIsOpen);
  }

  const closeMenu = () => {
    setMenuIsOpen(false);
  }

  return (
    <div
      className={`App view--${pageName} full-height${disableScrollRouteChange === true ? ' app--scroll--change' : ''}${window.USER_IS_TOUCHING === true ? ' user-is-touching' : ''}${motionIsEnabled === true ? ' motion-is-enabled' : ''}`}
      onMouseMove={showUI}
      onClick={(e) => {
        onLinkClick(e);
        showUI();
      }}
      onTouchStart={onFirstTouch}
      style={{
        backgroundColor: pageBackground,
        color: pageForeground
      }}
      {...swipeHandlers}
    >
      <Header
        pageForeground={pageForeground}
        pathName={router.pathname}
        pageBackground={pageBackground}
        menuIsOpen={menuIsOpen}
      />
      {
        menuIsOpen === false ?
          uiIsHidden === false || router.pathname.indexOf('courses/') === -1 ?
          <NavLinks
            pageForeground={pageForeground}
            pathName={router.pathname}
            pageBackground={pageBackground}
            menuIsOpen={menuIsOpen}
            /> : ''
        : ''
      }
      <div
        className={`sections__wrapper${menuIsOpen === true ? ' sections__wrapper--menu--open' : ''}`}
        onClick={closeMenu}
        onWheel={handleWheel}
        {...swipeHandlers}
      >


      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch>
          <Route exact path='/' render={(props) => (
            <LandingPage
              {...props}
              pageForeground={pageForeground}
              pathName={router.pathname}
              pageBackground={pageBackground}
              menuIsOpen={menuIsOpen}
              scrollDirection={scrollDirection}
              setScrollRouteChangeTimeout={setScrollRouteChangeTimeout}
              disableScrollRouteChange={disableScrollRouteChange}
            />
          )} />
          <Route exact path='/about' render={(props) => (
            <About
              {...props}
              aboutData={aboutData ? aboutData : ''}
              pageForeground={pageForeground}
              pathName={router.pathname}
              pageBackground={pageBackground}
              menuIsOpen={menuIsOpen}
              scrollDirection={scrollDirection}
              setScrollRouteChangeTimeout={setScrollRouteChangeTimeout}
              disableScrollRouteChange={disableScrollRouteChange}
            />
          )} />
          <Route exact path='/our-story' render={(props) => (
            <OurStory
              {...props}
              pageForeground={pageForeground}
              pathName={router.pathname}
              pageBackground={pageBackground}
              menuIsOpen={menuIsOpen}
              storyData={storyData}
              scrollDirection={scrollDirection}
              setScrollRouteChangeTimeout={setScrollRouteChangeTimeout}
              disableScrollRouteChange={disableScrollRouteChange}
            />
          )} />
          <Route exact path='/connect' render={(props) => (
            <Connect
              {...props}
              pageForeground={pageForeground}
              pathName={router.pathname}
              pageBackground={pageBackground}
              menuIsOpen={menuIsOpen}
              connectData={connectData}
              scrollDirection={scrollDirection}
              setScrollRouteChangeTimeout={setScrollRouteChangeTimeout}
              disableScrollRouteChange={disableScrollRouteChange}
            />
          )} />
          <Route exact path='/learn' render={(props) => (
            <Learn
              {...props}
              learnData={learnData}
              pageForeground={pageForeground}
              pathName={router.pathname}
              pageBackground={pageBackground}
              menuIsOpen={menuIsOpen}
              scrollDirection={scrollDirection}
              setScrollRouteChangeTimeout={setScrollRouteChangeTimeout}
              disableScrollRouteChange={disableScrollRouteChange}
            />
          )} />
          <Route exact path='/contact' render={(props) => (
            <Contact
              {...props}
              contactData={contactData}
              pageForeground={pageForeground}
              pathName={router.pathname}
              pageBackground={pageBackground}
              menuIsOpen={menuIsOpen}
              scrollDirection={scrollDirection}
              setScrollRouteChangeTimeout={setScrollRouteChangeTimeout}
              disableScrollRouteChange={disableScrollRouteChange}
            />
          )} />
          <Route exact path='/courses' render={(props) => (
            <Courses
              {...props}
              pageForeground={pageForeground}
              pathName={router.pathname}
              pageBackground={pageBackground}
              menuIsOpen={menuIsOpen}
              scrollDirection={scrollDirection}
            />
          )} />
          <Route exact path='/infographics' render={(props) => (
            <Infographics
              {...props}
              pageForeground={pageForeground}
              pathName={router.pathname}
              pageBackground={pageBackground}
              menuIsOpen={menuIsOpen}
            />
          )} />
          <Route exact path='/youtube' render={(props) => (
            <YouTube
              {...props}
              pageForeground={pageForeground}
              pathName={router.pathname}
              pageBackground={pageBackground}
              menuIsOpen={menuIsOpen}
            />
          )} />
          <Route exact path='/courses/:uid' render={(props) => (
            <Course
              {...props}
              pageForeground={pageForeground}
              pathName={router.pathname}
              pageBackground={pageBackground}
              menuIsOpen={menuIsOpen}
              uiIsHidden={uiIsHidden}
              showUI={showUI}
            />
          )} />
          <Route exact path='/privacy-policy' render={(props) => (
            <PrivacyPolicy
              {...props}
              pageForeground={pageForeground}
              pathName={router.pathname}
              pageBackground={pageBackground}
              menuIsOpen={menuIsOpen}
              scrollDirection={scrollDirection}
            />
          )} />
          {/* <Route exact path='/:uid/:pageNo' component={Home} /> */}
          {/* <Route component={NotFound} /> */}
          </Switch>
        </AnimatePresence>
      </div>
      <Menu pageForeground={pageForeground} pageBackground={pageBackground} menuIsOpen={menuIsOpen} route={router.pathname} contactData={contactData} />
      { uiIsHidden === false || router.pathname.indexOf('courses/') === -1 ?
        <MenuButton pageBackground={pageBackground} pageForeground={pageForeground} handleMenuButtonClick={handleMenuButtonClick} menuIsOpen={menuIsOpen} /> : ''
      }
      {
        pageName !== 'course' && menuIsOpen === false ?
        <MotionButton pageBackground={pageBackground} pageForeground={pageForeground} toggleMotionIsEnabled={toggleMotionIsEnabled} menuIsOpen={menuIsOpen} motionIsEnabled={motionIsEnabled} /> : ''
      }
    </div>
  );
}

const App = (props) => (
  <Route path="/" render={(props) => (
    <Content {...props} />
  )} />
);

export default App;

import { PayPalButton } from "react-paypal-button-v2";
import { useAuth0 } from "@auth0/auth0-react";
import { cockpitUrl } from './cockpit.js';
import { useState, useEffect } from 'react';

// sandbox

const addUserToCourse = (courseId, user) => {
  const apiKey = process.env.REACT_APP_API_KEY;

  fetch(`${cockpitUrl}/api/collections/get/courses?token=${apiKey}`)
    .then(response => response.json())
    .then(response => {

      let courseToUpdate;

      const courses = response.entries;
      if (courses) {
        if (courses[0]) {
          for (let course of courses) {
            if (course._id === courseId) {
              courseToUpdate = course;
            }
          }
        }
      }

      if (courseToUpdate) {

        if (courseToUpdate.users) {
        } else {
          courseToUpdate.users = [];
        }

        const userInfo = {
          value: {
            user_id: user.sub,
            name: user.name,
            email: user.email
          }
        }

        courseToUpdate.users.push(userInfo);

        fetch(`${cockpitUrl}/api/collections/save/courses?token=${apiKey}`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            data: courseToUpdate
          })
        })
          .then(res => res.json());
      }
  })
}

const createNewUser = (courseId, courseTitle, user) => {
  const apiKey = process.env.REACT_APP_API_KEY;

  fetch(`${cockpitUrl}/api/collections/save/users?token=${apiKey}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      data: {
        user_id: user.sub,
        name: user.name,
        email: user.email,
        courses: [
          {
            value: {
              course_id: courseId,
              course_title: courseTitle,
              answers: []
            }
          }
        ]
      }
    })
  })
  .then(res=>res.json());
}

const updateExistingUser = (courseId, courseTitle, cockpitUser) => {
  const apiKey = process.env.REACT_APP_API_KEY;

  const courseInfo = {
    value: {
      course_id: courseId,
      course_title: courseTitle,
      answers: []
    }
  };

  if (cockpitUser.courses) {
    cockpitUser.courses.push(courseInfo);
  }

  fetch(`${cockpitUrl}/api/collections/save/users?token=${apiKey}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      data: cockpitUser
    })
  })
  .then(res=>res.json());
}

const fetchUsersFromCockpit = (courseId, courseTitle, user) => {
  const apiKey = process.env.REACT_APP_API_KEY;

  fetch(`${cockpitUrl}/api/collections/get/users?token=${apiKey}`)
  .then(response => response.json())
  .then(response => {

    const auth0UserId = user.sub;

    const { entries } = response;
    let cockpitUser;

    if (entries) {
      for (let entry of entries) {
        if (entry.user_id === auth0UserId) {
          cockpitUser = entry;
        }
      }
    }

    if (cockpitUser) {
      updateExistingUser(courseId, courseTitle, cockpitUser, user);
    } else {
      createNewUser(courseId, courseTitle, user)
    }
    addUserToCourse(courseId, user);
  })
  .catch(error => console.log(error));
}

const PayWhatYouWantForm = (props) => {

  const { setPrice, price } = props;

  const handlePriceInput = (e) => {
    setPrice(e.target.value);
  }

  return (
    <div className="pay-what-you-want__wrapper">
      <form
        className="pay-what-you-want"
        onSubmit={
          (e) => {
            e.preventDefault();
          }
        }
      >
        <div className="pay-what-you-want__input__wrapper">
          <label
            htmlFor="pay what you want"
            className="pay-what-you-want__text"
          >Choose a price!
          </label>
          <input
            name="pay what you want"
            className="pay-what-you-want__input"
            type="number"
            min={0}
            value={price}
            onChange={handlePriceInput} />
        </div>
      </form>
    </div>
  )
}

const DiscountCodeForm = (props) => {

  const { updatePriceFromDiscountCode, userEnteredDiscountCodeIsIncorrect, discountIsActive, discountCode } = props;
  const [discountCodeInputValue, setDiscountCodeInputValue] = useState('');

  const handleDiscountCodeInput = (e) => {
    setDiscountCodeInputValue(e.target.value);
  }

  return (
    <div className="discount-code__wrapper">
      {
        discountIsActive === false ?
          <form
            className="discount-code"
            onSubmit={
              (e) => {
                e.preventDefault();
                updatePriceFromDiscountCode(discountCodeInputValue);
              }
            }
          >
            <label
              htmlFor="discount"
              className="discount-code__text"
            >
              {
                discountIsActive === false ?
                  userEnteredDiscountCodeIsIncorrect === true ?
                    'Sorry, we can’t find that discount code!'
                    :
                    'Have a discount code? Enter it here before paying!'
                  :
                  `Discount code applied: ${discountCode}`
              }
            </label>
            <div className="discount-code__input__wrapper">
              <input
                name="discount"
                className="discount-code__input"
                type="text"
                value={discountCodeInputValue}
                onChange={handleDiscountCodeInput} />
              <input
                name="discount code submit"
                className="discount-code__submit"
                type="submit"
                value="save" />
            </div>
          </form> : ''
      }
    </div>
  )
}

const Payment = (props) => {

  const { courseTitle, coursePrice, courseSynopsis, courseId, discountCode, priceAfterDiscountCode, payWhatYouWant, setCourseHomepageIsActive, setFinalPageIsActive } = props;
  const [price, setPrice] = useState(coursePrice);
  const [discountIsActive, setDiscountIsActive] = useState(false);
  const [userEnteredDiscountCodeIsIncorrect, setUserEnteredDiscountCodeIsIncorrect] = useState(false);

  const { user } = useAuth0();

  const getAuth0UserId = () => {
    fetchUsersFromCockpit(courseId, courseTitle, user);
  };

  useEffect(() => {
    if (discountIsActive === false && payWhatYouWant !== true) {
      setPrice(props.coursePrice)
    }
  }, [setDiscountIsActive, discountIsActive, props, setPrice, payWhatYouWant]);

  const updatePriceFromDiscountCode = (discountCodeInputValue) => {
    if (discountCodeInputValue.toLowerCase().trim() === discountCode.toLowerCase().trim()) {
      setPrice(priceAfterDiscountCode);
      setDiscountIsActive(true)
    } else {
      setUserEnteredDiscountCodeIsIncorrect(true);
    }
  }

  return (

    <div className="course__payment full-height">
      <div className="course__payment__inner">
        <div>
          <div>
            <div className="course__payment__heading">
              <h2 className="course__payment__title">{courseTitle}</h2>
              <h2 className="course__payment__price">£{price}</h2>
            </div>
            <p className="course__payment__synopsis">{courseSynopsis}</p>
            <p className="course__payment__synopsis">Please enter your payment details to access this course</p>
            {
              payWhatYouWant !== true && discountCode &&
              <DiscountCodeForm
                {...props}
                updatePriceFromDiscountCode={updatePriceFromDiscountCode}
                userEnteredDiscountCodeIsIncorrect={userEnteredDiscountCodeIsIncorrect}
                discountIsActive={discountIsActive}
                discountCode={discountCode}
              />
            }
            {
              payWhatYouWant === true &&
              <PayWhatYouWantForm setPrice={setPrice} />
            }
          </div>
          {
            window.origin.indexOf('localhost') > -1 ?
              <button
                onClick={() => {
                getAuth0UserId();
                props.updatePaymentStatusToPaid();
              }
              }>Click me!</button> : ''
          }
          <PayPalButton
            amount={discountIsActive === false ? price : priceAfterDiscountCode}
            onSuccess={() => {
              getAuth0UserId();
              props.updatePaymentStatusToPaid();
              setCourseHomepageIsActive(true);
              setFinalPageIsActive(false);
            }}
            options={{
              clientId: "AaFgg34Kr5gn02IM3XiHVjkY0Q_YGl7mL7zG3KZAIDDPYf1EOmnpo5Beu_86nANZpyuRGqQGT-XU8NF2",
              currency: 'GBP'
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Payment;

import { useState, useEffect } from 'react';
import PlayIcon from '../svg/PlayIcon.js';
import PauseIcon from '../svg/PauseIcon';
import VolumeIcon from '../svg/VolumeIcon.js';
import QuestionSection from './QuestionSection.js';
import { assetsPath } from './cockpit.js';

const onWindowResize = () => {
  const fullHeightElements = document.querySelectorAll('full-height');
  if (fullHeightElements[0]) {
    for (let fullHeightElement of fullHeightElements) {
      fullHeightElement.style.height = window.innerHeight;
    }
  }
}

const VideoPlayerChapters = (props) => {

  const { chapters, onClickChapterButton, activeChapter } = props;

  const [hoveredChapter, setHoveredChapter] = useState(-1);

  const handleMouseEnterChapterButton = (number) => {
    setHoveredChapter(number);
  }

  const handleMouseLeaveChapterButton = () => {
    setHoveredChapter(-1);
  }

  return (
    <div
      className="video-player__chapters__wrapper"
      onMouseLeave={handleMouseLeaveChapterButton}
    >
      <div className="video-player__chapters__inner">
        <div className="video-player__chapters__main">
          {
            chapters ?
              chapters.map(
                (chapter, i) => (
                  <button
                    className={`video-player__chapters__button video-player__chapters__button--${i + 1}${i === activeChapter - 1 ? ' active' : ''}`}
                    key={i}
                    onClick={() => { onClickChapterButton(i + 1) }}
                    onMouseEnter={() => { handleMouseEnterChapterButton(i) }}
                  >
                    <h3 className="video-player__chapters__button__number">
                      {i + 1}
                    </h3>
                    {
                      hoveredChapter === i ?
                      <div className="video-player__chapters__info__wrapper">
                        <div className="video-player__chapters__info__inner">
                          <div className="video-player__chapters__info__container">
                            <h3 className="video-player__chapters__info__title">{chapters[hoveredChapter].value.title}</h3>
                            <h3 className="video-player__chapters__info__text">{chapters[hoveredChapter].value.synopsis}</h3>
                          </div>
                          <div className="joint"></div>
                        </div>
                      </div>
                        : ''
                    }
                  </button>
                )
              )
            : ''
          }
        </div>
      </div>
    </div>
  )
}

const VideoPlayer = (props) => {

  const { video, pageBackground, chaptersAreShownOnVideoPlayer, handleChangeActiveChapter, uiIsHidden, questionIsActive, showQuestion, chapters } = props;

  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1);
  const [volumeBarIsVisible, setVolumeBarIsVisible] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [duration, setDuration] = useState(0);
  const [videoElement, setVideoElement] = useState({});
  const [stateActiveChapter, setStateActiveChapter] = useState(props.activeChapter);
  const [videoSrc, setVideoSrc] = useState(``);
  const [transcriptIsActive, setTranscriptIsActive] = useState(false);

  useEffect(() => {
    if (videoElement.className) {
      videoElement.volume = volume;
      setStateActiveChapter(props.activeChapter);
      if (chapters) {
        if (chapters[props.activeChapter - 1]) {
          if (chapters[props.activeChapter - 1].value) {
            if (chapters[props.activeChapter - 1].value.video) {
              setVideoSrc(`${assetsPath}/${chapters[props.activeChapter - 1].value.video}`);
            }
          }
        }

      }
    } else {
      if (document.querySelector('.video-player__video')) {
        setVideoElement(document.querySelector('.video-player__video'));
      }
    }
  }, [volume, videoElement, isPaused, video, props.activeChapter, setStateActiveChapter, setVideoSrc, chapters]);

  const onVideoCanPlay = (e) => {
    const currentVideoElement = e.target;
    setVideoElement(currentVideoElement);
    setDuration(currentVideoElement.duration);
    onWindowResize();
  }

  const handleVolumeButtonClick = () => {
    setVolumeBarIsVisible(!volumeBarIsVisible)
  }

  const handleScrubbing = (e) => {
    setIsPaused(true);
    videoElement.currentTime = e.target.value;
    setCurrentTime(e.target.value)
  }

  const onScrubbingBarMouseUp = (e) => {
    setIsPaused(false);
    videoElement.currentTime = e.target.value;
    setCurrentTime(e.target.value)
  }

  const handleVolume = (e) => {
    videoElement.volume = e.target.value;
    setVolume(e.target.value);
  }

  const videoPlayingLoop = () => {
    setCurrentTime(videoElement.currentTime);
  }

  const onVideoPlay = (e) => {
    setIsPaused(false);
  }

  const onVideoPause = (e) => {
    setIsPaused(true);
  }

  const toggleVideoPlay = () => {
    if (videoElement) {
      if (videoElement.paused === true) {
        videoElement.play()
        .catch((error) => console.log(error))
      } else {
        videoElement.pause();
      }
    }
  }

  const onVideoEnded = () => {
    setIsPaused(true);
    setCurrentTime(0);
    showQuestion(stateActiveChapter);
  }

  const onClickChapterButton = (number) => {
    handleChangeActiveChapter(number);
    setCurrentTime(0);
    onWindowResize();
  }

  return (
    <div className="video-player__wrapper"
      style={{
        height: window.innerHeight + 'px'
      }}
    >
      <div
        className="video-player__video__wrapper full-height"
        onClick={toggleVideoPlay}
        style={{
          height: window.innerHeight + 'px'
        }}
      >
        <video
          className="video-player__video"
          src={videoSrc}
          onCanPlay={onVideoCanPlay}
          onPlay={onVideoPlay}
          onTimeUpdate={videoPlayingLoop}
          onPause={onVideoPause}
          onEnded={onVideoEnded}
          playsInline={true}
        />
      </div>
      {
        questionIsActive === true ?
          <QuestionSection {...props} /> : ''
      }
      {
        uiIsHidden === false &&
        chapters &&
        chapters[stateActiveChapter - 1] &&
        chapters[stateActiveChapter - 1].value &&
        chapters[stateActiveChapter - 1].value.transcript &&
        chapters[stateActiveChapter - 1].value.transcript !== '' &&
        <button className="button--transcript" onClick={() => { setTranscriptIsActive(!transcriptIsActive) }}>transcript</button>
      }
      {
        transcriptIsActive === true &&
        chapters &&
        chapters[stateActiveChapter - 1] &&
        chapters[stateActiveChapter - 1].value &&
        chapters[stateActiveChapter - 1].value.transcript &&
        chapters[stateActiveChapter - 1].value.transcript !== '' &&
        <div className="video-player__transcript">
          <div className="video-player__transcript__inner">
            <button className="video-player__transcript__close" aria-label="close transcript" onClick={() => { setTranscriptIsActive(false) }}>
              <div className="video-player__transcript__close__inner"></div>
            </button>
            <div className="video-player__transcript__text__wrapper">
              <p className="video-player__transcript__text">{chapters[stateActiveChapter - 1].value.transcript}</p>
            </div>
          </div>
        </div>
      }
      {
        uiIsHidden === false && questionIsActive === false ?
          <div className="video-player__ui">
            {
              chaptersAreShownOnVideoPlayer === false ?
                <div
                  className="video-player__controls__wrapper"
                  style={{
                    background: pageBackground
                  }}
                >
                  <div className="video-player__controls__inner">
                    <button
                      className="video-player__button--play-pause"
                      onClick={toggleVideoPlay}
                    >
                      {
                        isPaused === true ?
                          <PlayIcon />
                          :
                          <PauseIcon />
                      }
                    </button>
                    <input
                      className="video-player__time"
                      type="range"
                      min="0"
                      max={duration}
                      value={currentTime}
                      onChange={handleScrubbing}
                      onMouseUp={onScrubbingBarMouseUp}
                    />
                    <button
                      className="video-player__button--volume"
                      onClick={handleVolumeButtonClick}
                    >
                      <VolumeIcon />
                    </button>
                    {
                      volumeBarIsVisible === true ?
                        <div
                          className="video-player__volume__wrapper"
                          style={{
                            background: pageBackground
                          }}
                        >
                          <input
                            className="video-player__volume"
                            type="range"
                            min="0"
                            max="1"
                            step="0.1"
                            value={volume}
                            onChange={handleVolume}
                          />
                        </div>
                        : ''
                    }
                  </div>
                </div>
                :
                <VideoPlayerChapters {...props} onClickChapterButton={onClickChapterButton} />
            }
          </div> : ''
      }
    </div>
  )
}

export { VideoPlayer, VideoPlayerChapters };
const PlayIcon = (props) => (
  <svg
    className="icon icon--play"
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="40"
    viewBox="0 0 29 40"
    fill={props.pageForeground ? props.pageForeground : '#000'}
  >
    <path
      fill={props.pageForeground ? props.pageForeground : '#000'}
      className="svg__polygon"
      data-name="Polygon 1"
      d="M16.707,4.775a4,4,0,0,1,6.586,0L35.675,22.729A4,4,0,0,1,32.382,29H7.618a4,4,0,0,1-3.293-6.271Z"
      transform="translate(29) rotate(90)"
    />
  </svg>
)

export default PlayIcon;
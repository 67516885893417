import { useState, useEffect, useCallback } from "react";
import { motion } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import { Link } from 'react-router-dom';

const stringQuestion = 'Welcome to';
const arrayQuestion = [];
for (let char of stringQuestion) {
  const character = {
    character: char,
    hiddenOnStart: true,
    speed: (Math.random() * 0.001) + 0.00025,
    direction: Math.random() > 0.5 ? 1 : -1
  }
  arrayQuestion.push(character);
}

const stringTitle = 'Climate in Colour';
const arrayTitle = [];
for (let char of stringTitle) {
  const character = {
    character: char,
    hiddenOnStart: false,
    speed: (Math.random() * 0.001) + 0.00025,
    direction: Math.random() > 0.5 ? 1 : -1
  }
  arrayTitle.push(character);
}

const TitleLetter = (props) => {

  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    setWindowHeight(window.innerHeight);
  },[]);

  return (
    <span
      className={`title__span${props.hiddenOnStart === true && props.initialised === false ? ' title__wrapper__hidden-text' : ''}`}
      style={{
        'top': (windowHeight * props.scrollAmount * props.speed * props.direction) + 'px',
        'color' : `rgba(0,0,0,${1 - (props.scrollAmount * props.speed * 4)})`
      }}>
      {props.children}
    </span>
  )
};

let wheelTimeout;
let wheelTimeoutInterval;
let wheelTimeoutIsActive = false;

function LandingPage(props) {

  const [landingPageInitialised, setLandingPageInitialised] = useState(false);
  const [landingPageComponentInitialised, setLandingPageComponentInitialised] = useState(false);
  const [scrollAmount, setScrollAmount] = useState(0);

  const onWheelTimeout = useCallback(() => {
      clearInterval(wheelTimeoutInterval);
      if (wheelTimeoutIsActive === false) {
        wheelTimeoutIsActive = true;
        let newScrollAmount = scrollAmount;
        wheelTimeoutInterval = setInterval(() => {
          if (newScrollAmount > 0) {
            setScrollAmount(newScrollAmount);
            newScrollAmount--;
          } else {
            clearInterval(wheelTimeoutInterval);
            wheelTimeoutIsActive = false;
          }
        }, 2);
      }
    }, [scrollAmount]
  )

  useEffect(() => {
    if (landingPageComponentInitialised === false) {
      setLandingPageComponentInitialised(true);
      const direction = props.scrollDirection;
      if (direction === -1) {
        setScrollAmount(0);
        setLandingPageInitialised(true);
      } else {
        setTimeout(() => {
          setLandingPageInitialised(true);
        }, 900);
      }
    }
  }, [landingPageComponentInitialised, props.scrollDirection, onWheelTimeout]);

  const onMouseWheel = (event) => {
    const prevScrollAmount = scrollAmount;
    let newScrollAmount = prevScrollAmount + event.deltaY;

    const section = document.querySelector('.section');

    clearInterval(wheelTimeoutInterval);
    clearTimeout(wheelTimeout);
    wheelTimeout = setTimeout(onWheelTimeout, 900);

    let sectionLength = 0;
    if (section) {
      sectionLength = section.offsetHeight;

      if (newScrollAmount >= 0 && newScrollAmount <= sectionLength) {
        setScrollAmount(newScrollAmount);
      } else if (newScrollAmount > sectionLength) {
        props.history.push('/about');
      }
    }
  }

  const handleSwipedUp = () => {
    //next
    props.history.push('/about');
  }

  const handleSwiping = (e) => {
    const prevScrollAmount = scrollAmount;
    //is swiped event and inexplicably the delta Y works the opposite way lol so here we remedy this
    let newScrollAmount = prevScrollAmount - e.deltaY;
    setScrollAmount(newScrollAmount);
  }

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => handleSwipedUp(),
    onSwiping: (e) => handleSwiping(e)
  });

  return (
    <motion.div
      initial={{ y: 0 }}
      animate={{ y: 0 }}
      exit={{ y: 0 }}
      className={`section section--landing${props.menuIsOpen === true ? ' section--menu-is-open' : ''}`}
      style={{
        height: window.innerHeight + 'px'
      }}
      onWheel={onMouseWheel}
      {...swipeHandlers}
    >
        <div className={`font--heading title__wrapper${landingPageInitialised === true ? ' title__wrapper--initialised' : ''}`} title="Welcome to Climate in Colour">
          <p className="title__wrapper__text title__wrapper__question tc">
            {arrayQuestion.map(
              (char, i) => (
                <TitleLetter
                  key={i}
                  scrollAmount={scrollAmount}
                  {...char}
                  initialised={landingPageInitialised}
                  hiddenOnStart={true}>
                  {char.character}
                </TitleLetter>
              )
            )}
          </p>
          <h1 className="title__wrapper__text title tc">
            {arrayTitle.map(
              (char, i) => (
                <TitleLetter
                  key={i}
                  scrollAmount={scrollAmount}
                  {...char}>
                  {char.character}
                </TitleLetter>
              )
            )}
          </h1>
        </div>
        <div className="landing-page__scroll-on-text__wrapper">
          <p className="scroll-on-text float">
            <Link to="/about">
              Scroll to begin
            </Link>
          </p>
        </div>
    </motion.div>
  )

}

export default LandingPage;
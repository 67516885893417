import { useState, useEffect } from 'react';
import { VideoPlayerChapters } from './VideoPlayer.js';
import { cockpitUrl } from './cockpit.js';
import logo from '../img/logo192.png';
import logoTealWhite from '../img/logo192--teal-white.png';
import colors from './colors.js';
import html2canvas from 'html2canvas';

const saveAs = (uri, filename) => {
  var link = document.createElement('a');
  if (typeof link.download === 'string') {
    link.href = uri;
    link.download = filename;

    //Firefox requires the link to be in the body
    document.body.appendChild(link);

    //simulate click
    link.click();

    //remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(uri);
  }
}

const CourseFinalPageQuestionAnswerSet = (props) => {

  const { index, handleEditButtonClick, value } = props;
  const { question, answer } = value;
  const { orange, teal } = colors;

  const handleDownloadButtonClick = (isQuestion) => {
    let downloadableItem = document.querySelector(`#answer${index}`);
    if (isQuestion === true) {
      downloadableItem = document.querySelector(`#question${index}`);
    }

    if (downloadableItem) {
      html2canvas(downloadableItem)
        .then((canvas) => {
          saveAs(canvas.toDataURL(), 'My reflections from The Colonial History Of Climate.png');
        });
    }
  }

  return (
    <li className="course__final-page__question-answer-set__wrapper">
      <div className="course__final-page__question-answer-set__question__wrapper">
        <div className="course__final-page__question-answer-set__question__outer">
          <div className="course__final-page__question-answer-set__question__inner course__downloadable course__downloadable--question"
            id={`question${index}`}
            style={{
              backgroundColor: orange
            }}
          >
            <img src={logo} alt="Climate in Colour logo" className="logo" />
            <p className="course__final-page__question-answer-set__question">
              {question}
            </p>
          </div>
        </div>
        <div className="course__final-page__question-answer-set__buttons__wrapper">
          <button
            aria-label="Download this question"
            className="course__button--download"
            onClick={(e) => { handleDownloadButtonClick(true) }}>Download</button>
        </div>
      </div>
      <div className="course__final-page__question-answer-set__answer__wrapper">
        <div className="course__final-page__question-answer-set__answer__outer">
          <div className="course__final-page__question-answer-set__answer__inner course__downloadable course__downloadable--answer"
            id={`answer${index}`}
            style={{
              backgroundColor: teal
            }}>
            <img src={logoTealWhite} alt="Climate in Colour logo" className="logo" />
            <p className="course__final-page__question-answer-set__answer">
              {answer}
            </p>
          </div>
        </div>
        <div className="course__final-page__question-answer-set__buttons__wrapper">
          <button
            aria-label="Edit this answer"
            className="course__answer__button--edit"
            onClick={(e) => { handleEditButtonClick(index, question, answer) }}
          >Edit answer</button>
          <button
            aria-label="Download this answer"
            className="course__button--download"
            onClick={(e) => { handleDownloadButtonClick(false) }}
          >Download</button>
        </div>
      </div>
    </li>
  )
}

const CourseFinalPageEditOverlay = (props) => {

  const { answerToChange, answerToChangeText, questionToChangeText, handleCancelEditAnswer, handleSaveAnswer } = props;
  const [answerContent, setAnswerContent] = useState(answerToChangeText);

  const handleInput = (e) => {
    setAnswerContent(e.target.value);
  }

  return (
    <div className="course__final-page__overlay--edit__wrapper full-height"
      style={{
        height: window.innerHeight + 'px',
        width: window.innerWidth + 'px',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 9
      }}
    >
      <div className="course__final-page__overlay--edit">
        <label htmlFor="answer" className="course__final-page__overlay--edit__question">{questionToChangeText}</label>
        <textarea
          className="course__final-page__overlay--edit__input--answer"
          rows={12}
          maxLength={640}
          value={answerContent}
          onChange={handleInput}
          name="answer"
        >
        </textarea>
        <div className="course__final-page__overlay--edit__buttons__wrapper">
          <button
            aria-label="cancel edits and close popup"
            onClick={
              handleCancelEditAnswer
            }
            className="course__final-page__overlay--edit__button course__final-page__overlay--edit__button--cancel">Cancel</button>
          <button
            aria-label="Save answer"
            onClick={
              () => {
                handleSaveAnswer(answerContent, answerToChange)
              }
            }
            className="course__final-page__overlay--edit__button course__final-page__overlay--edit__button--save">Save</button>
        </div>
      </div>
    </div>
  )
}

const CourseFinalPage = (props) => {

  const { getUserDataForThisCourse, currentCockpitUser } = props;
  const [userDataToDisplay, setUserDataToDisplay] = useState({});
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const [answerToChange, setAnswerToChange] = useState(-1);
  const [answerToChangeText, setAnswerToChangeText] = useState('');
  const [questionToChangeText, setQuestionToChangeText] = useState('');

  const handleCancelEditAnswer = () => {
    setAnswerToChange(-1);
  }

  const handleSaveAnswer = (answerContent, answerToChange) => {

    if (currentCockpitUser) {
      if (currentCockpitUser.courses) {
        if (currentCockpitUser.courses[0]) {
          for (let course of currentCockpitUser.courses) {
            if (course.value) {
              if (course.value.course_id === props.courseData._id) {
                if (course.value.answers) {
                  if (course.value.answers[answerToChange]) {
                    if (course.value.answers[answerToChange].value) {
                      course.value.answers[answerToChange].value.answer = answerContent;

                      const apiKey = process.env.REACT_APP_API_KEY;

                      fetch(`${cockpitUrl}/api/collections/save/users?token=${apiKey}`, {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                          data: currentCockpitUser
                        })
                      })
                      .then(res=>res.json())
                        .then(entry => {
                          setHasBeenEdited(true);
                          setUserDataToDisplay(course.value);
                          getUserDataForThisCourse(props.courseData._id);
                          setAnswerToChange(-1);
                        });

                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  const handleEditButtonClick = (index, question, answer) => {
    setAnswerToChange(index);
    setAnswerToChangeText(answer);
    setQuestionToChangeText(question);
  }

  useEffect(() => {
    if (props.courseData._id) {
      if (!userDataToDisplay.course_id) {
        getUserDataForThisCourse(props.courseData._id);
      }
      if (hasBeenEdited === false) {
        setUserDataToDisplay(props.currentUserCourseData);
      }
    }
  }, [props.currentUserCourseData, getUserDataForThisCourse, props.courseData._id, userDataToDisplay.course_id, hasBeenEdited])

  return (
    <div className="course__final-page__wrapper full-height">
      {
        answerToChange > -1 ?
          <CourseFinalPageEditOverlay
            {...props}
            answerToChange={answerToChange}
            answerToChangeText={answerToChangeText}
            questionToChangeText={questionToChangeText}
            handleCancelEditAnswer={handleCancelEditAnswer}
            handleSaveAnswer={handleSaveAnswer}
          /> : ''
      }
      <h2 className="course__final-page__title">{props.title}</h2>
      <VideoPlayerChapters {...props} onClickChapterButton={props.handleChangeActiveChapter} />
        {
          props.courseData.final_page_intro ?
          <p className="course__final-page__intro">
            {props.courseData.final_page_intro}
          </p> : ''
        }
      <div className="course__final-page__content__wrapper">
        <div className="course__final-page__content__outer">
          <ul className="course__final-page__content__list--answers">
            {
              userDataToDisplay.answers ?
                userDataToDisplay.answers[0] ?
                  userDataToDisplay.answers.map(
                    (item, i) => (
                      <CourseFinalPageQuestionAnswerSet
                        handleEditButtonClick={handleEditButtonClick}
                        index={i}
                        {...item}
                        key={i}
                      />
                    )
                  )
                : 'You haven’t answered any questions yet!'
              : 'You haven’t answered any questions yet!'
            }
          </ul>
        </div>
      </div>
      {
        props.courseData ? props.courseData.feedback_link ?
          <a className="course__feedback__button" rel="noreferrer" target="_blank" href={props.courseData.feedback_link}>Feedback</a> : '' : ''
      }
    </div>
  )
}

export default CourseFinalPage;
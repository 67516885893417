import { useState, useEffect, useRef } from 'react';
import PlayIcon from '../svg/PlayIcon.js';
import PauseIcon from '../svg/PauseIcon';
import VolumeIcon from '../svg/VolumeIcon.js';

const AudioPlayer = ({ audio, id, pageBackground, pageForeground }) => {

  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1);
  const [volumeBarIsVisible, setVolumeBarIsVisible] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [duration, setDuration] = useState(0);
  const [audioElement, setAudioElement] = useState({});

  useEffect(() => {
    if (audioElement.className) {
      audioElement.volume = volume;
    } else {
      if (document.querySelector('.audio-player__audio')) {
        setAudioElement(document.querySelector('.audio-player__audio'));
      }
    }

  }, [volume, audioElement, isPaused]);

  const onAudioCanPlay = (e) => {
    const currentAudioElement = e.target;
    setAudioElement(currentAudioElement);
    setDuration(currentAudioElement.duration);
  }

  const handleVolumeButtonClick = () => {
    setVolumeBarIsVisible(!volumeBarIsVisible)
  }

  const handlePlayPauseClick = () => {
    if (audioElement) {
      if (audioElement.paused === true) {
        audioElement.play()
        .catch(
          (error) => console.log(error)
        )
      } else {
        audioElement.pause()
      }
    }
    setIsPaused(!isPaused);
  }

  const handleScrubbing = (e) => {
    setIsPaused(true);
    audioElement.currentTime = e.target.value;
    setCurrentTime(e.target.value)
  }

  const onScrubbingBarMouseUp = (e) => {
    setIsPaused(false);
    audioElement.currentTime = e.target.value;
    setCurrentTime(e.target.value)
  }

  const handleVolume = (e) => {
    audioElement.volume = e.target.value;
    setVolume(e.target.value);
  }

  const audioPlayingLoop = () => {
    setCurrentTime(audioElement.currentTime);
  }

  const onAudioPlay = (e) => {
    setIsPaused(false);
  }

  const onAudioPaused = (e) => {
    setIsPaused(true);
  }

  const onAudioEnded = () => {
    setIsPaused(true);
    setCurrentTime(0);
  }

  const audioRef = useRef();

  return (
    <div className="audio-player__wrapper">
      <audio
        className="audio-player__audio"
        ref={audioRef}
        src={audio}
        onCanPlay={onAudioCanPlay}
        onLoad={onAudioCanPlay}
        onPlay={onAudioPlay}
        onTimeUpdate={audioPlayingLoop}
        onPause={onAudioPaused}
        onEnded={onAudioEnded}
      />
      <button
        aria-label={isPaused === true ? "Play button" : "Pause button"}
        className="audio-player__button--play-pause"
        onClick={handlePlayPauseClick}
      >
        {
          isPaused === true ?
            <PlayIcon />
          :
            <PauseIcon />
        }
      </button>
      <input
        aria-label="Audio player time slider"
        name="time"
        className="audio-player__time"
        type="range"
        min="0"
        max={duration}
        value={currentTime}
        onChange={handleScrubbing}
        onMouseUp={onScrubbingBarMouseUp}
      />
      <button
        aria-label={`${volumeBarIsVisible === true ? 'hide' : 'show'} Audio player volume controls`}
        className="audio-player__button--volume"
        onClick={handleVolumeButtonClick}
      >
        <VolumeIcon />
      </button>
      {
        volumeBarIsVisible === true ?
          <div
            className="audio-player__volume__wrapper"
            style={{
              background: pageBackground
            }}
          >
            <input
              name="volume"
              aria-label="Audio player volume slider"
              className="audio-player__volume"
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={volume}
              onChange={handleVolume}
            />
          </div>
          : ''
      }
    </div>
  )
}

export default AudioPlayer;